import React, { ReactElement } from 'react';
import { DiagnosisCategory } from '../../types/diagnosis/diagnosis-category.type';
import DiagnosisListCard from '../../constants/diagnosis/diagnosis-list.card';
import DiagnosisCategoryLoader from '../../constants/diagnosis/loader/diagnosis-category.loader';
export default function DiagnosisCategoryContain(props: {
	diagnosisCategory: DiagnosisCategory | null;
	fetchFn?: Function;
}): ReactElement {
	return (
		<div className={'my-12 items-center'}>
			{props.diagnosisCategory ? (
				<>
					<div className={'font-bold text-xl'}>{props.diagnosisCategory.title}</div>
					<div className={'font-light mb-5'}>{props.diagnosisCategory.sub_title}</div>
				</>
			) : (
				<DiagnosisCategoryLoader />
			)}
			<div>
				{props.diagnosisCategory ? (
					props.diagnosisCategory!.diagnosis_array.map((e, k) => (
						<DiagnosisListCard key={k} diagnosis={e} />
					))
				) : (
					<DiagnosisListCard diagnosis={null} fetchFn={props.fetchFn} />
				)}
			</div>
		</div>
	);
}
