import { useState, useEffect } from 'react';
import requestUtil from '../../utils/request.util';
import authUtil from '../../utils/auth.util';
import DiagnosisResultCardContain from '../../containers/diagnosis/diagnosis-result-card.contain';
import { DiagnosisResultGraphType } from '../../types/diagnosis/diagnosis-result-graph.type';
import { DiagnosisResultArrayType } from '../../types/diagnosis/diagnosis-result-array.type';
import DiagnosisEmptyContain from '../../containers/diagnosis/diagnosis-empty.contain';

export function DiagnosisResultHook(props: { sendData?: any; sendFormData?: any }) {
	const [DxResultArrClass, setDxResultArrClass] = useState<DiagnosisResultArrayType | undefined>(
		undefined
	);
	const [DxGraph, setDxGraph] = useState<DiagnosisResultGraphType | undefined>(undefined);
	const [loading, setLoading] = useState<Boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);
	const userToken = authUtil.getCurrentUser();
	const saveDiagnosis = async (): Promise<void> => {
		const sendData = props.sendData;
		if (sendData) {
			const imageURL = sendData.imageURL;
			if (imageURL) {
				await fetch(imageURL)
					.then((res) => res.blob())
					.then((imageBlob) => {
						const age = sendData.age;
						const gender = sendData.gender;
						let formData = new FormData();
						formData.append('age', age);
						formData.append('gender', gender);
						formData.append('image', imageBlob, 'image.png');
						requestUtil(
							`${userToken ? '/diagnosis/scalp/area' : '/diagnosis/none/user/scalp/area'}`,
							{
								method: 'POST',
								headers: {
									bayabas: userToken ? userToken.jwtToken : '',
								},
								body: formData,
							}
						).then((res) => {
							if (res.success === false) {
								setError(new Error(res.message));
							} else {
								!userToken && localStorage.setItem(`dx4_log_id`, res.data.id);
							}
						});
					});
			} else {
				const response = await requestUtil(`${userToken ? '/diagnosis' : '/diagnosis/none/user'}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'bayabas': userToken ? userToken.jwtToken : '',
					},
					body: JSON.stringify(sendData),
				});
				!userToken &&
					localStorage.setItem(`diagnosis_${sendData.diagnosis_id}`, response.data.dx_log_id);
				if (response.success === false) {
					await setError(new Error(response.message));
				}
			}
		}
	};
	const getDxResult = async (): Promise<void> => {
		const dx_log_id = localStorage.getItem('diagnosis_1');
		const dx4_log_id = localStorage.getItem('dx4_log_id');
		let body;
		if (dx_log_id && dx4_log_id) {
			body = {
				dx_log_id,
				dx4_log_id,
			};
		} else if (dx_log_id) {
			body = {
				dx_log_id,
			};
		} else if (dx4_log_id) {
			body = {
				dx4_log_id,
			};
		}
		if (userToken || dx_log_id || dx4_log_id) {
			const response = await requestUtil(
				`${userToken ? '/diagnosis/result/0' : '/diagnosis/none/user/result/0'}`,
				{
					method: userToken ? 'GET' : 'POST',
					headers: userToken
						? { bayabas: userToken.jwtToken }
						: { 'Content-Type': 'application/json' },
					body: body && JSON.stringify(body),
				}
			);
			if (response.success === false) {
				await setError(response.message);
			} else {
				await setDxResultArrClass(new DiagnosisResultArrayType(response.data));
			}
		} else {
			setDxResultArrClass(undefined);
		}
	};
	const getDxResultGraph = async (): Promise<void> => {
		const dxLogId_1 = localStorage.getItem('diagnosis_1');
		if (userToken || dxLogId_1) {
			const response = await requestUtil(
				`${userToken ? '/diagnosis/result/graph' : '/diagnosis/none/user/graph'}`,
				{
					method: userToken ? 'GET' : 'POST',
					headers: userToken
						? { bayabas: userToken.jwtToken }
						: { 'Content-Type': 'application/json' },
					body:
						dxLogId_1 &&
						JSON.stringify({
							dx_log_id: dxLogId_1,
						}),
				}
			);
			if (response.success === false) {
				await setError(response.message);
			} else {
				response.data.category = JSON.parse(response.data.category);
			}
			await setDxGraph(
				new DiagnosisResultGraphType(response.data.category, JSON.parse(response.data.after_score))
			);
		} else {
			setDxGraph(undefined);
		}
	};
	useEffect(() => {
		setLoading(true);
		const dxSave = async (): Promise<void> => {
			await saveDiagnosis();
		};
		const dxReturn = async (): Promise<void> => {
			await getDxResult();
		};
		const dxGraphReturn = async (): Promise<void> => {
			await getDxResultGraph();
		};
		dxSave().then(() =>
			setTimeout(
				() => {
					dxReturn().then();
					dxGraphReturn().then(() => {
						setTimeout(() => {
							setLoading(false);
						}, 300);
					});
				},
				props.sendData ? 5000 : 0
			)
		);
		/* eslint-disable */
	}, []);
	if (loading)
		return (
			<>
				<DiagnosisResultCardContain loading={true} />
			</>
		);
	if (error) return <>에러</>;
	if (!DxResultArrClass) return <DiagnosisEmptyContain />;
	return (
		<>
			<DiagnosisResultCardContain
				loading={false}
				dxResultArr={DxResultArrClass}
				dxGraph={DxGraph}
			/>
		</>
	);
}
