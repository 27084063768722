import React from 'react';
import ContentLoader from 'react-content-loader';

const DiagnosisResultGraphLoader = () => (
	<ContentLoader
		speed={2}
		width={350}
		height={395}
		viewBox="0 0 350 350"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<circle cx="180" cy="190" r="159" />
	</ContentLoader>
);

export default DiagnosisResultGraphLoader;
