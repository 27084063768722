import React, { ReactElement } from 'react';
import { ProductArrayType } from '../../types/product/productArray.type';
import RecommendedProductCard from '../../constants/product/recommended-product.card';

export default function RecommendedProductContain(props: {
	isLoading?: Boolean;
	Products: ProductArrayType;
}): ReactElement {
	return (
		<>
			{props.Products?.recommendedProds.length !== 0 ? (
				<div className={'grid grid-cols-3 gap-10 sm:grid-cols-none'}>
					{props.Products.recommendedProds.map((e, k) => {
						return <RecommendedProductCard product={e} key={k} />;
					})}
				</div>
			) : (
				<div className={'mt-3 text-xl font-bold pt-10'}>
					아쉽지만
					<br /> 추천되는 제품이 아직 없어요. 😭
				</div>
			)}
		</>
	);
}
