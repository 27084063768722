import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiCheckSquare, FiClipboard, FiHome, FiUser } from 'react-icons/fi';
import { UserType } from '../../types/user.type';
import authUtil from '../../utils/auth.util';

export default function TobNavContain(): ReactElement {
	const [scrollPoint, setScrollPoint] = useState<number>(0);
	const [navIndex, setNavIndex] = useState<number | null>(null);
	const [userInfo, setUserInfo] = useState<UserType | null>(null);
	const [isDrop, setIsDrop] = useState<boolean>(false);
	const [isCollaboratePath, setIsCollaboratePath] = useState<boolean>(false);
	const path = useLocation().pathname;
	useEffect(() => {
		dropDownClose();
		window.addEventListener('scroll', handleScroll);
	}, [scrollPoint]);
	useEffect(() => {
		dropDownClose();
		switch (path) {
			case '/':
				setNavIndex(0);
				break;
			case '/diagnosis':
				setNavIndex(0);
				break;
			case '/diagnosis/list':
				setNavIndex(1);
				break;
			case `/diagnosis/result`:
				setNavIndex(2);
				break;
			case '/auth':
				setNavIndex(3);
				break;
			default:
				setNavIndex(null);
		}
		setIsCollaboratePath(!!path.match('collaborate'));
		const localStorageData = localStorage.getItem('user');
		if (localStorageData) {
			const userInfoModel = new UserType();
			const userLocalData = localStorage.getItem('user') ?? null;
			if (userLocalData) {
				userInfoModel.setUser(JSON.parse(userLocalData));
			} else {
				localStorage.removeItem('auth');
			}
			setUserInfo(userInfoModel);
		}
	}, [path]);
	const handleScroll = () => {
		let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
		let { scrollHeight, clientHeight } = document.documentElement;
		let scrollTop = winScroll / (scrollHeight - clientHeight);
		setScrollPoint(scrollTop);
	};
	const handleDropMenu = () => {
		setIsDrop(!isDrop);
	};
	const logout = () => {
		authUtil.logout();
	};
	const dropDownClose = () => {
		setIsDrop(false);
	};
	return (
		<>
			<div
				className={`z-50 fixed bottom-16 right-8 mb-3 bg-white p-3 drop-shadow-2xl font-light rounded-sm w-44 ${
					isDrop ? '' : 'hidden'
				}`}
			>
				<ul>
					<li onClick={logout} className={''}>
						로그아웃
					</li>
				</ul>
			</div>
			<div className={`nav-group w-full ${isCollaboratePath ? 'hidden' : ''}`}>
				<nav
					className={
						(scrollPoint >= 0.1 ? 'slide-in ' : 'slide-out ') +
						'w-screen place-items-center grid overflow-hidden fixed sm:hidden'
					}
					style={{
						backgroundColor: '#F8F8F8',
						height: '80px',
						display: 'none',
					}}
				>
					<div
						className={'flex items-center leading-9 text-sm'}
						style={{
							width: '1200px',
						}}
					>
						<div className={'flex space-x-8 items-center'}>
							<Link to={'/'} className={'mr-3 overflow-hidden'}>
								<img
									src={require('../../assets/images/logo.png')}
									className={'w-24'}
									alt={'logo'}
								/>
							</Link>
							<Link to={'/diagnosis/list'} className={navIndex === 1 ? 'text-green-500' : ''}>
								<span>진단 구경하기</span>
							</Link>
							<Link to={'/diagnosis/result'} className={navIndex === 2 ? 'text-green-500' : ''}>
								나의 진단서 보기
							</Link>
							<Link
								to={'/diagnosis/result/recommended'}
								className={navIndex === 3 ? 'text-green-500' : ''}
							>
								나의 맞춤 추천
							</Link>
						</div>
						{userInfo ? (
							<>
								<p className={'ml-auto'}>
									<span className={'font-bold'}>{userInfo.nickname}</span> 님
								</p>
								<button
									className={'w-10 h-10 rounded-3xl overflow-hidden ml-3'}
									onClick={handleDropMenu}
								>
									<img
										src={
											userInfo.profile_image_url ??
											'https://t1.daumcdn.net/cfile/tistory/2513B53E55DB206927'
										}
										alt={'profile_image'}
									/>
								</button>
							</>
						) : (
							<Link
								to={'/auth'}
								className={'ml-auto font-bold ' + (navIndex === 4 ? 'text-green-500' : '')}
							>
								로그인
							</Link>
						)}
					</div>
				</nav>
				<nav
					className={
						(scrollPoint >= 0.1 ? 'bottom-slide-in ' : 'bottom-slide-out ') +
						`w-full p-4 px-8 bg-white overflow-hidden fixed hidden sm:block z-50 bottom-0`
					}
					style={{
						boxShadow: '0 2px 4px 0 rgba(0,0,0,.3)',
						display: 'none',
					}}
				>
					{/* Select by hyeon water*/}
					<div
						className={'text-2xl text-gray-600 grid grid-cols-4 place-items-center gap-10 sm:gap-8'}
					>
						<Link to={'/'} className={navIndex === 0 ? 'text-green-500' : 'text-black'}>
							<FiHome />
							<p
								className={'text-xs text-center'}
								style={{
									fontSize: '10px',
								}}
							>
								메인
							</p>
						</Link>

						<Link
							to={'/diagnosis/list'}
							className={navIndex === 1 ? 'text-green-500' : 'text-black'}
						>
							<FiCheckSquare className={'m-auto'} />
							<p
								className={'text-xs text-center'}
								style={{
									fontSize: '10px',
								}}
							>
								진단하기
							</p>
						</Link>
						<Link
							to={'/diagnosis/result'}
							className={navIndex === 2 ? 'text-green-500' : 'text-black'}
						>
							<FiClipboard className={'m-auto'} />
							<p
								className={'text-xs text-center'}
								style={{
									fontSize: '10px',
								}}
							>
								진단결과
							</p>
						</Link>
						{userInfo ? (
							<>
								<button
									onClick={handleDropMenu}
									className={`w-10 h-10 rounded-3xl overflow-hidden`}
								>
									<img
										src={
											userInfo.profile_image_url ??
											'https://t1.daumcdn.net/cfile/tistory/2513B53E55DB206927'
										}
										alt={'none_profile_image'}
									></img>
									<p className={'text-xs text-center'}>MY</p>
								</button>
							</>
						) : (
							<Link to={'/auth'} className={navIndex === 3 ? 'text-green-500' : 'text-black'}>
								<FiUser />
								<p
									className={'text-xs text-center'}
									style={{
										fontSize: '10px',
									}}
								>
									MY
								</p>
							</Link>
						)}
					</div>
				</nav>
			</div>
		</>
	);
}
