import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { DiagnosisItemHook } from '../../hooks/diagnosis/diagnosis-item.hook';

export default function PinkdaDiagnosisItemPage(props: { isLogin: boolean }): ReactElement {
	const { id } = useParams();

	return (
		<>
			<div className="container max-w-xl min-h-screen">
				<div className="w-full mt-40 sm:mt-0 grid grid-cols-2 mb-6 sm:p-6 sm:pb-0">
					<div className={'flex items-center scale-75 sm:scale-100'}>
						<img src={require('../../../../assets/images/bayabas_x_pink.png')} alt="logo" />
					</div>
					{/*<div className={'relative'}>*/}
					{/*	<Link to={'/diagnosis/list'}>*/}
					{/*		<FiXCircle className={'text-2xl h-full absolute right-0 hover:text-green-700'} />*/}
					{/*	</Link>*/}
					{/*</div>*/}
				</div>
				<DiagnosisItemHook diagnosisId={id!.toString()} />
			</div>
		</>
	);
}
