import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './assets/tailwind.css';
import './assets/animation.css';
import './assets/custorm.css';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import App from './App';
import ScrollToTop from './constants/base/ScrollToTop';
import TobNavContain from './containers/base/tob-nav.contain';
import FooterContain from './containers/base/footer.contain';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<>
		<div
			style={{
				backgroundColor: '#F8F8F8',
			}}
		>
			<BrowserRouter>
				<ScrollToTop />
				<TobNavContain />
				<div
					style={{
						backgroundColor: '#F8F8F8',
						display: 'flex',
						justifyContent: 'space-around',
						minWidth: '320px',
					}}
				>
					<App />
				</div>
				<FooterContain />
			</BrowserRouter>
		</div>
	</>
);
