import React from 'react';
import ContentLoader from 'react-content-loader';

const DiagnosisItemLoader = () => (
	<ContentLoader
		speed={2}
		width={320}
		height={52}
		viewBox="0 0 500 80"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="0" y="8" rx="2" ry="2" width="500" height="33" />
		<rect x="0" y="55" rx="2" ry="2" width="330" height="25" />
	</ContentLoader>
);

export default DiagnosisItemLoader;
