import { useState, useEffect, ReactElement } from 'react';
import requestUtil from '../../utils/request.util';
import DiagnosisCategoryContain from '../../containers/diagnosis/diagnosis-category.contain';
import authUtil from '../../utils/auth.util';
import { SolutionArrayType } from '../../types/solution/solutionArray.type';
import RecommendedSolutionContain from '../../containers/solution/recommended-solution.contain';
import DiagnosisEmptyContain from '../../containers/diagnosis/diagnosis-empty.contain';

export function RecommendedSolutionHook(): ReactElement {
	const [recommendedSolutions, setRecommendedSolutions] = useState<SolutionArrayType | undefined>(
		undefined
	);
	const [loading, setLoading] = useState<Boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);
	const userToken = authUtil.getCurrentUser();

	const fetchDiagnosis = async () => {
		try {
			setError(undefined);
			setRecommendedSolutions(undefined);
			setLoading(true);
			const dxLogId_1 = localStorage.getItem('diagnosis_1');
			if (dxLogId_1 || userToken) {
				const response = await requestUtil(
					`${userToken ? '/solution/recommended' : '/solution/none/user/recommended'}`,
					{
						method: userToken ? 'GET' : 'POST',
						headers: {
							'Content-Type': 'application/json',
							'bayabas': userToken ? userToken.jwtToken : '',
						},
						body:
							dxLogId_1 &&
							JSON.stringify({
								dx_log_id: dxLogId_1,
							}),
					}
				);
				if (response.status === 400) {
					setRecommendedSolutions(undefined);
				} else {
					setRecommendedSolutions(new SolutionArrayType(response.data));
				}
			} else {
				setRecommendedSolutions(undefined);
			}
		} catch (e: any) {
			setError(e);
		}
		// setLoading(false);
		setTimeout(() => setLoading(false), 300);
	};

	useEffect(() => {
		fetchDiagnosis().then();
		/* eslint-disable */
	}, []);
	if (loading)
		return (
			<>
				<DiagnosisCategoryContain diagnosisCategory={null} />
			</>
		);
	if (error)
		return (
			<>
				<div>{error.toString()}</div>
				<DiagnosisCategoryContain diagnosisCategory={null} fetchFn={fetchDiagnosis} />
			</>
		);
	if (!recommendedSolutions) return <DiagnosisEmptyContain />;
	return (
		<>
			<RecommendedSolutionContain solutions={recommendedSolutions} />
		</>
	);
}
