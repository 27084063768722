import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { DiagnosisItem } from '../../types/diagnosis/diagnosis-item.type';
import { DiagnosisResultHook } from '../../hooks/diagnosis/diagnosis-result.hook';

interface selectIndexArrType {
	selectIndexArr: Array<number>;
	diagnosisItemArr: Array<DiagnosisItem>;
}

export default function PinkdaDiagnosisResultPage(this: any): ReactElement {
	try {
		let sendData: any;
		const location = useLocation();
		if (location.state) {
			const { selectIndexArr } = location.state as selectIndexArrType;
			const { diagnosisItemArr } = location.state as selectIndexArrType;
			sendData = {
				diagnosis_id: diagnosisItemArr[0].diagnosis_id,
				data: [],
			};
			diagnosisItemArr.forEach((e, index) => {
				e.value = null;
				e.index = selectIndexArr[index];
				sendData.data.push(e);
			});
		}
		return <DiagnosisResultHook sendData={sendData} />;
	} catch (e: any) {
		return <>정상적인 접근이 아닙니다. 다시 시도해 주세요. 🥺</>;
	}
}
