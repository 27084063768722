import React, { ReactElement, useState } from 'react';
import { DiagnosisItem } from '../../types/diagnosis/diagnosis-item.type';
import { Link } from 'react-router-dom';
import DiagnosisItemCard from '../../constants/diagnosis/diagnosis-item.card';
import { toast } from 'react-toastify';
import DiagnosisItemLoader from '../../constants/diagnosis/loader/diagnosis-item.loader';
import { Line } from 'rc-progress';

export default function DiagnosisItemContain(props: {
	diagnosisItemArr: Array<DiagnosisItem> | null;
}): ReactElement {
	const [selectIndexArr, setSelectIndex] = useState<Array<number>>([]);
	const [currentIndex, setCurrentIndex] = useState<number>(-1);
	const [itemIndex, setItemIndex] = useState<number>(0);

	const insertSelectIndex = (selectIndex: number): void => {
		selectIndexArr[itemIndex] = selectIndex;
		setSelectIndex(selectIndexArr);
	};
	const getPreItemSelectIndex = (): number => {
		return selectIndexArr[itemIndex - 1];
	};
	const getNextItemSelectIndex = (): number => {
		return selectIndexArr.length && selectIndexArr[itemIndex + 1] !== undefined
			? selectIndexArr[itemIndex + 1]
			: -1;
	};

	const selectItem = (selectIndex: number) => {
		setCurrentIndex(selectIndex);
		insertSelectIndex(selectIndex);
		if (itemIndex === props.diagnosisItemArr!.length - 1) {
		} else {
			setTimeout(() => {
				setCurrentIndex(-1);
				setItemIndex(itemIndex + 1);
			}, 100);
		}
	};

	let btn_class0 = 'rounded-full m-auto h-9 w-9 ' + (currentIndex === 0 ? 'bg-pink-500' : '');
	let btn_class1 = 'rounded-full m-auto h-9 w-9 ' + (currentIndex === 1 ? 'bg-pink-500' : '');
	let btn_class2 = 'rounded-full m-auto h-9 w-9 ' + (currentIndex === 2 ? 'bg-pink-500' : '');
	let btn_class3 = 'rounded-full m-auto h-9 w-9 ' + (currentIndex === 3 ? 'bg-pink-500' : '');
	let btn_class4 = 'rounded-full m-auto h-9 w-9 ' + (currentIndex === 4 ? 'bg-pink-500' : '');
	return (
		<>
			{props.diagnosisItemArr ? (
				<Line
					percent={
						props.diagnosisItemArr ? ((itemIndex + 1) / props.diagnosisItemArr.length) * 100 : 0
					}
					strokeColor={'hotpink'}
					trailColor={'#E5E7EB'}
					className={'h-2 w-11/12 m-auto'}
				/>
			) : (
				<div className="indio h-2 bg-gray-200" />
			)}
			<div className="body p-10 pt-8 sm:p-3">
				<div className="contents grid grid-cols-2 gap-10 place-content-center">
					{props.diagnosisItemArr ? (
						<DiagnosisItemCard diagnosisItem={props.diagnosisItemArr[itemIndex]} />
					) : (
						<DiagnosisItemLoader />
					)}
					<div className="my-10"></div>
					<div
						className={`overflow-auto grid grid-cols-5 p-3 ${props.diagnosisItemArr ?? 'hidden'}`}
					>
						<div
							onClick={() => {
								selectItem(0);
							}}
						>
							<div
								className={btn_class0}
								style={{
									border: 'solid 1px #696969',
								}}
							></div>
							<div id="0" className="text-center font-light text-sm sm:text-xs mt-1">
								전혀 아니에요
							</div>
						</div>
						<div
							onClick={() => {
								selectItem(1);
							}}
						>
							<div
								className={btn_class1}
								style={{
									border: 'solid 1px #696969',
								}}
							></div>
							<div id="1" className="text-center font-light text-sm sm:text-xs mt-1">
								아니에요
							</div>
						</div>
						<div
							onClick={() => {
								selectItem(2);
							}}
						>
							<div
								className={btn_class2}
								style={{
									border: 'solid 1px #696969',
								}}
							></div>
							<div id="2" className="text-center font-light text-sm sm:text-xs mt-1">
								보통
							</div>
						</div>
						<div
							onClick={() => {
								selectItem(3);
							}}
						>
							<div
								className={btn_class3}
								style={{
									border: 'solid 1px #696969',
								}}
							></div>
							<div id="3" className="text-center font-light text-sm sm:text-xs mt-1">
								그래요
							</div>
						</div>
						<div
							onClick={() => {
								selectItem(4);
							}}
						>
							<div
								className={btn_class4}
								style={{
									border: 'solid 1px #696969',
								}}
							></div>
							<div id="4" className="text-center font-light text-sm sm:text-xs mt-1">
								매우 그래요
							</div>
						</div>
					</div>
				</div>
				<div
					className={`button-wrap grid gap-10 place-content-center mt-10 sm:mt-12 sm:p-10 sm:pt-0 ${
						itemIndex === 0 ? 'grid-cols-1' : 'grid-cols-2'
					}`}
				>
					{props.diagnosisItemArr ? (
						<>
							{itemIndex !== 0 ? (
								<button
									onClick={() => {
										if (itemIndex !== 0) {
											setCurrentIndex(getPreItemSelectIndex());
											setItemIndex(itemIndex - 1);
										}
									}}
									className="
										w-full
										px-3 py-3
										text-white text-gray-600 sm:rounded-full
									"
									style={{
										height: '48px',
										border: 'solid 1px #696969',
										fontSize: '0.7em',
									}}
								>
									이전
								</button>
							) : (
								''
							)}
							{itemIndex === props.diagnosisItemArr!.length - 1 ? (
								<Link
									onClick={() => {
										if (currentIndex === -1) {
											toast.warn('진단항목을 선택해 주세요.');
										} else {
											insertSelectIndex(currentIndex);
										}
									}}
									to={`${currentIndex === -1 ? '' : '/collaborate/pinkda/diagnosis/result'}`}
									state={{ selectIndexArr, diagnosisItemArr: props.diagnosisItemArr }}
								>
									<button
										className="
				                      w-full
				                      px-3 py-3
				                      bg-white
				                      text-pink-800 text-pink-500 sm:rounded-full
				                  "
										style={{
											height: '48px',
											border: 'solid 1px hotpink',
											fontSize: '0.7em',
										}}
									>
										결과보기
									</button>
								</Link>
							) : (
								<button
									onClick={() => {
										if (currentIndex === -1) {
											toast.warn('진단항목을 선택해 주세요.');
										} else {
											setItemIndex(itemIndex + 1);
											insertSelectIndex(currentIndex);
											setCurrentIndex(getNextItemSelectIndex());
										}
									}}
									className="
				          	w-full
				          	px-3 py-3
				          	bg-white sm:rounded-full
				          	text-pink-800 text-pink-400
				          "
									style={{
										height: '48px',
										border: 'solid 1px hotpink',
										fontSize: '0.7em',
									}}
								>
									다음
								</button>
							)}
						</>
					) : (
						<button
							className="
				      	w-full
				      	px-3 py-3
				      	bg-white sm:rounded-full
				      	text-gray-500 text-gray-300
				      "
							style={{
								height: '48px',
								border: 'solid 1px hotpink',
								fontSize: '0.7em',
							}}
						>
							LOADING...
						</button>
					)}
				</div>
			</div>
		</>
	);
}
