import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export default function DiagnosisEmptyContain(): ReactElement {
	return (
		<div className={'min-h-screen'}>
			<img
				src={require('../../../../assets/images/ouch.png')}
				alt={'ouch'}
				className={'scale-75 m-auto mt-16'}
			/>
			<h1 className={'text-xl text-center'}>앗, 아직 진단을 하지 않으셨네요.</h1>
			<p className={'text-center text-center mt-3 text-sm'}>
				바야바즈 진단을 통해 나의 두피 상태와
				<br />
				컨디션에 따른 맞춤 추천을 받아보세요!
			</p>
			<div
				className={
					'grid place-content-center grid-cols-2 text-center mb-5 mx-36 gap-10 sm:grid-cols-none sm:gap-2 sm:mx-0 mt-10'
				}
			>
				<Link
					to={`/diagnosis/list`}
					className="
                          sm:w-screen
                              border-2 border-slate-500
                              p-2
                              bg-slate-500
                              text-white font-light text-sm
                              hover:shadow-md
                      "
				>
					<button>진단하기</button>
				</Link>
				<Link
					to={`/auth`}
					className="
                          sm:w-screen
                          border-2 border-slate-400
                          p-2
                          bg-white hover:shadow-md
                          font-medium text-slate-500 text-sm
                      "
				>
					<button>로그인</button>
				</Link>
			</div>
		</div>
	);
}
