import React, { ReactElement } from 'react';
import { AuthTermHook } from '../../hooks/auth/auth.term.hook';

export default function SignUpTermContents(props: {
	setPageIndex: Function;
	setAgreeTerms: Function;
}): ReactElement {
	return (
		<div className={'flex flex-col text-sm'}>
			<AuthTermHook setAgreeTerms={props.setAgreeTerms} setPageIndex={props.setPageIndex} />
		</div>
	);
}
