import React, { ReactElement, useState } from 'react';
import requestUtil from '../../utils/request.util';

export default function SignUpEmailContents(props: {
	setUserEmail: Function;
	setPageIndex: Function;
}): ReactElement {
	const [email, setEmail] = useState<string>('');
	const [emailAble, setEmailAble] = useState<boolean | null>(null);
	const [isNext, setIsNext] = useState<boolean>(false);
	const onEmailHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setIsNext(false);
		setEmailAble(null);
		setEmail(e.currentTarget.value);
	};
	const focusOutEmailCheck = (): void => {
		const validMail: boolean = isEmail();
		if (validMail) {
			emailUseCheck().then();
		} else {
			setEmailAble(false);
		}
	};
	const isEmail = () => {
		const emailRegex =
			// eslint-disable-next-line
			/^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
		return emailRegex.test(email);
	};
	const emailUseCheck = async (): Promise<void> => {
		const response = await requestUtil(`/user/use/check/email`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				value: email,
			}),
		});
		const emailUse: boolean = response.data.use;
		await setEmailAble(emailUse);
		await setIsNext(emailUse);
	};
	const next = (): void => {
		props.setPageIndex(2);
		props.setUserEmail(email);
	};
	return (
		<div className={'flex flex-col text-sm font-light'}>
			<div className={'relative'}>
				<input
					className={'py-4 px-5 w-full'}
					placeholder={'이메일을 입력해주세요.'}
					value={email}
					onChange={onEmailHandler}
				></input>
				<button
					onClick={focusOutEmailCheck}
					className={`
					  h-full 
						absolute right-0 pr-3
						font-medium text-slate-600
					`}
				>
					중복검사
				</button>
			</div>

			<div className={'flex items-center my-3'}>
				<span
					className={(emailAble ?? ' hidden') + (!emailAble ? ' text-red-400' : ' text-green-500')}
				>
					{!emailAble
						? '이메일 형식에 맞지않거나 이미 사용중인 이메일 입니다.'
						: '사용 가능한 이메일 입니다.'}
				</span>
			</div>

			<button
				onClick={next}
				className={
					'mt-10 py-4 text-white' + (!isNext ? ' bg-gray-500 cursor-not-allowed' : ' bg-green-500')
				}
				disabled={!isNext}
			>
				이메일 인증
			</button>
		</div>
	);
}
