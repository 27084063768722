import React from 'react';
import ContentLoader from 'react-content-loader';

const DiagnosisCategoryLoader = () => (
	<ContentLoader
		speed={2}
		width={280}
		height={85}
		viewBox="0 0 500 100"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="1" y="65" rx="10" ry="10" width="380" height="25" />
		<rect x="1" y="0" rx="10" ry="10" width="200" height="50" />
	</ContentLoader>
);

export default DiagnosisCategoryLoader;
