// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/radar
import { ResponsiveRadar } from '@nivo/radar';
import { ReactElement } from 'react';

export default function DiagnosisGraphRadar(props: {
	data: Record<string, unknown>[];
}): ReactElement {
	return (
		<div className={'h-96 mt-10 sm:mt-1'}>
			<ResponsiveRadar
				data={props.data}
				keys={['score']}
				indexBy="category"
				valueFormat=">-.2f"
				margin={{ top: 5, right: 45, bottom: 5, left: 35 }}
				borderWidth={1}
				borderColor={{ from: 'color', modifiers: [] }}
				gridLevels={3}
				gridLabelOffset={1}
				colors={'#22C55E'}
				fillOpacity={0.5}
				blendMode="multiply"
				motionConfig="molasses"
				maxValue={100}
				legends={[]}
			/>
		</div>
	);
}
