import React, { ReactElement, useEffect, useState } from 'react';
import { DiagnosisItem } from '../../types/diagnosis/diagnosis-item.type';
import { Link } from 'react-router-dom';
import DiagnosisItemCard from '../../constants/diagnosis/diagnosis-item.card';
import { toast } from 'react-toastify';
import DiagnosisItemLoader from '../../constants/diagnosis/loader/diagnosis-item.loader';
import { Line } from 'rc-progress';
import requestUtil from '../../utils/request.util';

export default function DiagnosisItemContain(props: {
	diagnosisItemArr: Array<DiagnosisItem> | null;
}): ReactElement {
	const [selectIndexArr, setSelectIndex] = useState<Array<number>>([]);
	const [currentIndex, setCurrentIndex] = useState<number>(-1);
	const [itemIndex, setItemIndex] = useState<number>(0);
	const [currentItemType, setCurrentItemType] = useState<String>('');
	const [imageURL, setImageURL] = useState<string>('');

	const insertSelectIndex = (selectIndex: number): void => {
		selectIndexArr[itemIndex] = selectIndex;
		setSelectIndex(selectIndexArr);
	};
	const getPreItemSelectIndex = (): number => {
		return selectIndexArr[itemIndex - 1];
	};
	const getNextItemSelectIndex = (): number => {
		return selectIndexArr.length && selectIndexArr[itemIndex + 1] !== undefined
			? selectIndexArr[itemIndex + 1]
			: -1;
	};

	const selectItem = (selectIndex: number) => {
		setCurrentIndex(selectIndex);
		insertSelectIndex(selectIndex);
		if (itemIndex === props.diagnosisItemArr!.length - 1) {
		} else {
			setTimeout(() => {
				setCurrentIndex(-1);
				setItemIndex(itemIndex + 1);
			}, 100);
		}
	};

	const encodeFileToBase64 = (imageFile: File) => {
		const reader: any = new FileReader();
		reader.readAsDataURL(imageFile);
		return new Promise<void>((resolve) => {
			reader.onload = () => {
				const imageURL = reader.result;
				setImageURL(imageURL);
				resolve();
			};
		});
	};

	useEffect(() => {
		if (props.diagnosisItemArr) {
			let currentQuestionType = props.diagnosisItemArr[itemIndex]['question_type'];
			setCurrentItemType(currentQuestionType);
		}
	}, [currentIndex, props.diagnosisItemArr]);

	let btn_class0 =
		'rounded-full m-auto h-9 w-9 bg-white ' + (currentIndex === 0 ? 'bg-green-400' : '');
	let btn_class1 =
		'rounded-full m-auto h-9 w-9 bg-white ' + (currentIndex === 1 ? 'bg-green-400' : '');
	let btn_class2 =
		'rounded-full m-auto h-9 w-9 bg-white ' + (currentIndex === 2 ? 'bg-green-400' : '');
	let btn_class3 =
		'rounded-full m-auto h-9 w-9 bg-white ' + (currentIndex === 3 ? 'bg-green-400' : '');
	let btn_class4 =
		'rounded-full m-auto h-9 w-9 bg-white ' + (currentIndex === 4 ? 'bg-green-400' : '');
	return (
		<>
			{currentItemType === '5votes' && (
				<Line
					percent={
						props.diagnosisItemArr ? ((itemIndex + 1) / props.diagnosisItemArr.length) * 100 : 0
					}
					strokeColor={'#26C672'}
					trailColor={'#E5E7EB'}
					className={'h-2 w-11/12 m-auto'}
				/>
			)}
			<div className="body p-10 pt-8 sm:p-3">
				<div className="contents grid grid-cols-2 gap-10 place-content-center">
					{props.diagnosisItemArr ? (
						<DiagnosisItemCard diagnosisItem={props.diagnosisItemArr[itemIndex]} />
					) : (
						<DiagnosisItemLoader />
					)}
					<div className={`my-10`}>
						{currentItemType === '5votes' ? (
							<div className={`overflow-auto grid grid-cols-5 p-3`}>
								<div
									onClick={() => {
										selectItem(0);
									}}
								>
									<div
										className={btn_class0}
										style={{
											border: 'solid 1px #696969',
										}}
									></div>
									<div id="0" className="text-center font-light text-sm sm:text-xs mt-1">
										전혀 아니에요
									</div>
								</div>
								<div
									onClick={() => {
										selectItem(1);
									}}
								>
									<div
										className={btn_class1}
										style={{
											border: 'solid 1px #696969',
										}}
									></div>
									<div id="1" className="text-center font-light text-sm sm:text-xs mt-1">
										아니에요
									</div>
								</div>
								<div
									onClick={() => {
										selectItem(2);
									}}
								>
									<div
										className={btn_class2}
										style={{
											border: 'solid 1px #696969',
										}}
									></div>
									<div id="2" className="text-center font-light text-sm sm:text-xs mt-1">
										보통
									</div>
								</div>
								<div
									onClick={() => {
										selectItem(3);
									}}
								>
									<div
										className={btn_class3}
										style={{
											border: 'solid 1px #696969',
										}}
									></div>
									<div id="3" className="text-center font-light text-sm sm:text-xs mt-1">
										그래요
									</div>
								</div>
								<div
									onClick={() => {
										selectItem(4);
									}}
								>
									<div
										className={btn_class4}
										style={{
											border: 'solid 1px #696969',
										}}
									></div>
									<div id="4" className="text-center font-light text-sm sm:text-xs mt-1">
										매우 그래요
									</div>
								</div>
							</div>
						) : currentItemType === 'gender' ? (
							<div className={`overflow-auto grid grid-cols-2 gap-12 p-3`}>
								<div
									onClick={() => {
										selectItem(0);
									}}
								>
									<div
										className={`grid place-content-center rounded-lg m-auto h-14 ${
											currentIndex === 0 ? 'bg-green-500 text-white' : 'bg-white text-slate-500'
										}`}
									>
										<div id="0" className="text-center">
											남성
										</div>
									</div>
								</div>
								<div
									onClick={() => {
										selectItem(1);
									}}
								>
									<div
										className={`grid place-content-center rounded-lg m-auto h-14 ${
											currentIndex === 1 ? 'bg-green-500 text-white' : 'bg-white text-slate-500'
										}`}
									>
										<div id="1" className="text-center">
											여성
										</div>
									</div>
								</div>
							</div>
						) : currentItemType === 'age' ? (
							<div className={`overflow-auto grid grid-cols-2 gap-6 p-3`}>
								<div
									onClick={() => {
										selectItem(0);
									}}
								>
									<div
										className={`grid place-content-center rounded-lg m-auto h-14 ${
											currentIndex === 0 ? 'bg-green-500 text-white' : 'bg-white text-slate-500'
										}`}
									>
										<div id="0" className="text-center">
											10대
										</div>
									</div>
								</div>
								<div
									onClick={() => {
										selectItem(1);
									}}
								>
									<div
										className={`grid place-content-center rounded-lg m-auto h-14 ${
											currentIndex === 1 ? 'bg-green-500 text-white' : 'bg-white text-slate-500'
										}`}
									>
										<div id="1" className="text-center">
											20대
										</div>
									</div>
								</div>
								<div
									onClick={() => {
										selectItem(2);
									}}
								>
									<div
										className={`grid place-content-center rounded-lg m-auto h-14 ${
											currentIndex === 2 ? 'bg-green-500 text-white' : 'bg-white text-slate-500'
										}`}
									>
										<div id="2" className="text-center">
											30대
										</div>
									</div>
								</div>
								<div
									onClick={() => {
										selectItem(3);
									}}
								>
									<div
										className={`grid place-content-center rounded-lg m-auto h-14 ${
											currentIndex === 3 ? 'bg-green-500 text-white' : 'bg-white text-slate-500'
										}`}
									>
										<div id="3" className="text-center">
											40대
										</div>
									</div>
								</div>
								<div
									onClick={() => {
										selectItem(4);
									}}
								>
									<div
										className={`grid place-content-center rounded-lg m-auto h-14 ${
											currentIndex === 4 ? 'bg-green-500 text-white' : 'bg-white text-slate-500'
										}`}
									>
										<div id="4" className="text-center">
											50대
										</div>
									</div>
								</div>
								<div
									onClick={() => {
										selectItem(5);
									}}
								>
									<div
										className={`grid place-content-center rounded-lg m-auto h-14 ${
											currentIndex === 5 ? 'bg-green-500 text-white' : 'bg-white text-slate-500'
										}`}
									>
										<div id="5" className="text-center">
											60대 이상
										</div>
									</div>
								</div>
							</div>
						) : currentItemType === 'image' ? (
							<div className={`overflow-auto`}>
								<img
									src={require('../../assets/images/image-input-guide.png')}
									alt={'image input guide'}
								/>
								<h3 className={'mt-12 mb-6'}>사진 등록</h3>
								<div className="image-upload">
									<label htmlFor="file-input">
										{imageURL ? (
											<img src={imageURL} alt={'image input image'} className={'w-1/2'} />
										) : (
											<img
												src={require('../../assets/images/image-input.png')}
												alt={'image input image'}
												className={'w-1/2'}
											/>
										)}
									</label>
									<input
										id="file-input"
										type="file"
										className={'hidden'}
										accept="image/*"
										onChange={(e: any) => {
											encodeFileToBase64(e.target.files[0]).then();
										}}
									/>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
				</div>
				<div
					className={`button-wrap grid gap-10 place-content-center mt-10 sm:mt-12 sm:p-10 sm:pt-0 ${
						itemIndex === 0 ? 'grid-cols-1' : 'grid-cols-2'
					}`}
				>
					{props.diagnosisItemArr ? (
						<>
							{itemIndex !== 0 ? (
								<button
									onClick={() => {
										if (itemIndex !== 0) {
											setCurrentIndex(getPreItemSelectIndex());
											setItemIndex(itemIndex - 1);
										}
									}}
									className="
										w-full bg-white
										px-3 py-3
										text-white text-gray-600 sm:rounded-full
									"
									style={{
										height: '48px',
										border: 'solid 1px #696969',
										fontSize: '0.7em',
									}}
								>
									이전
								</button>
							) : (
								''
							)}
							{itemIndex === props.diagnosisItemArr!.length - 1 ? (
								<Link
									onClick={() => {
										console.log();
										if (currentIndex === -1 && !imageURL) {
											return toast.warn('진단항목을 선택해 주세요.');
										} else {
											insertSelectIndex(currentIndex);
										}
									}}
									to={`${currentIndex === -1 && !imageURL ? '' : '/diagnosis/result'}`}
									state={{ selectIndexArr, diagnosisItemArr: props.diagnosisItemArr, imageURL }}
								>
									<button
										className="
				                      w-full
				                      px-3 py-3
				                      bg-white
				                      text-green-700 text-main sm:rounded-full
				                  "
										style={{
											height: '48px',
											border: 'solid 1px green',
											fontSize: '0.7em',
										}}
									>
										결과보기
									</button>
								</Link>
							) : (
								<button
									onClick={() => {
										if (currentIndex === -1) {
											toast.warn('진단항목을 선택해 주세요.');
										} else {
											setItemIndex(itemIndex + 1);
											insertSelectIndex(currentIndex);
											setCurrentIndex(getNextItemSelectIndex());
										}
									}}
									className="
				          	w-full
				          	px-3 py-3
				          	bg-white sm:rounded-full
				          	text-green-700
				          "
									style={{
										height: '48px',
										border: 'solid 1px green',
										fontSize: '0.7em',
									}}
								>
									다음
								</button>
							)}
						</>
					) : (
						<button
							className="
				      	w-full
				      	px-3 py-3
				      	bg-white sm:rounded-full
				      	text-green-700
				      "
							style={{
								height: '48px',
								border: 'solid 1px green',
								fontSize: '0.7em',
							}}
						>
							LOADING...
						</button>
					)}
				</div>
			</div>
		</>
	);
}
