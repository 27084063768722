import React, { ReactElement, useEffect, useState } from 'react';
import DiagnosisResultCardLoader from './loader/diagnosis-result-card.loader';
import { DiagnosisResultType } from '../../types/diagnosis/diagnosis-result.type';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function DiagnosisResultCard(props: {
	cardType?: String;
	loading?: Boolean;
	dxResult?: DiagnosisResultType;
}): ReactElement {
	const [percentage, setPercentage] = useState<Number>(0);
	const imgUrl = props.dxResult?.tag_image_url;
	useEffect(() => {
		setTimeout(
			() =>
				setPercentage(
					props.dxResult?.user_tag_avg === undefined ? 0 : +props.dxResult.user_tag_avg
				),
			100
		);
		/* eslint-disable */
	}, [props.loading]);
	return (
		<>
			<div
				className="font-bold sm:grid-cols-2 mt-24 sm:mt-10 sm:mb-5 m-auto"
				style={{
					maxWidth: '940px',
				}}
			>
				{props.loading ? (
					<div className={'sm:overflow-hidden sm:w-60'}>
						<DiagnosisResultCardLoader />
					</div>
				) : (
					<>
						<div className="grid grid-cols-3 text-2xl sm:grid-cols-none sm:block sm:px-3">
							{props.cardType === 'indicator' ? (
								<>
									<div className="col-span-2 text-2xl sm:mt-10">
										<p className={'text-sm text-gray-400 mb-1 font-medium'}>
											내 두피의 위험도는 어떨까요?
										</p>
										{props.dxResult?.name} 위험도 :
										<span className="text-green-500 text-3xl">
											{' '}
											{props.dxResult?.user_tag_avg}%
										</span>
										<div className="rounded-full w-1/2 m-auto hidden sm:block my-10">
											<CircularProgressbar
												value={+percentage}
												text={`${percentage}%`}
												styles={buildStyles({
													pathColor: `#22C55E`,
													textColor: '#000000',
													pathTransitionDuration: 2,
												})}
											/>
										</div>
										<p className="text-lg font-light mt-5 leading-7 pr-10 sm:pr-0 my-auto">
											{props.dxResult?.level_description}
										</p>
									</div>
									<div className="rounded-full m-auto w-7/12 sm:hidden">
										<CircularProgressbar
											value={+percentage}
											text={`${percentage}%`}
											styles={buildStyles({
												pathColor: `#22C55E`,
												textColor: '#000000',
												pathTransitionDuration: 2,
											})}
										/>
									</div>
								</>
							) : (
								<>
									<div
										className="overflow-hidden rounded-full m-auto sm:w-2/3 sm:hidden"
										style={{
											width: '180px',
											height: '180px',
										}}
									>
										<img
											alt="scalp image"
											src={imgUrl?.toString()}
											className={'m-auto scale-75'}
											style={{
												objectFit: 'contain',
												width: '180px',
												height: '180px',
											}}
										/>
									</div>
									<div className="col-span-2 text-2xl px-5 sm:px-0 my-auto">
										{props.dxResult?.name} 위험도 :
										<span className="text-green-500 text-3xl">
											{' '}
											{props.dxResult?.user_tag_avg}%
										</span>
										<div
											className="overflow-hidden rounded-full m-auto w-2/3 hidden sm:block my-5"
											style={{
												width: '180px',
												height: '180px',
											}}
										>
											<img
												alt="scalp image"
												src={imgUrl?.toString()}
												className={'m-auto scale-75'}
												style={{
													objectFit: 'contain',
													width: '180px',
													height: '180px',
												}}
											/>
										</div>
										<p className="text-lg font-light mt-5 leading-7 sm:mb-12">
											{props.dxResult?.level_description}
										</p>
									</div>
								</>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
}
