import { ReactElement } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

export default function Error(props: { fetchFn: Function }): ReactElement {
	return (
		<>
			<button
				className={
					'grid text-center text-white place-items-center absolute w-full h-full bg-black opacity-40 hover:underline underline-offset-2'
				}
				onClick={() => props.fetchFn()}
			>
				<div className={''}>
					<h1 className={'text-5xl'}>
						<FiAlertTriangle className={'m-auto'} />
					</h1>
					ERROR
				</div>
			</button>
		</>
	);
}
