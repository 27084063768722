export class DiagnosisResultGraphType {
	category: Array<String>;
	after_score: Array<Number>;

	constructor(category: Array<String>, after_score: Array<Number>) {
		this.category = category;
		this.after_score = after_score;
	}

	getData = (): any => {
		let graphData: { category: String; score: Number }[] = [];
		this.category.forEach((e, index) => {
			if (e !== '건성' && e !== '지성') {
				let thisData = {
					category: e,
					score: this.after_score[index],
				};
				graphData.push(thisData);
			}
		});
		return graphData;
	};
}
