import React, { ReactElement, useEffect, useState } from 'react';
import SignUpEmailContents from '../../containers/auth/sign-up-email.contents';
import SignUpEmailSendContents from '../../containers/auth/sign-up-email.send.contents';
import SignUpPasswordContents from '../../containers/auth/sign-up-password.contents';
import SignUpNicknameContents from '../../containers/auth/sign-up-nickname.contents';
import SignUpTermContents from '../../containers/auth/sign-up-term.contents';
import SignUpDoneContain from '../../containers/auth/sign-up-done.contain';
import requestUtil from '../../utils/request.util';

export default function SignUpPage(): ReactElement {
	const [thisIndexElement, setThisIndexElement] = useState<ReactElement>(<></>);
	const [userEmail, setUserEmail] = useState<string>('');
	const [pageTransition, setPageTransition] = useState<string>('');
	const [userPassword, setUserPassword] = useState<string>('');
	const [userNickname, setUserNickname] = useState<string>('');
	const [pageIndex, setPageIndex] = useState<number>(1);
	const [agreeTerms, setAgreeTerms] = useState<Array<number>>([]);

	useEffect(() => {
		if (pageIndex !== 1) {
			setPageTransition('page-out');
			setTimeout(() => {
				setPageTransition('page-in');
			}, 150);
			setTimeout(() => {
				setPageTransition('');
			}, 300);
		}
		if (pageIndex === 6) {
			requestUtil(`/user/signup`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					uid: userEmail,
					password: userPassword,
					auth_type: 'local',
					nickname: userNickname,
				}),
			}).then(() => {
				requestUtil(`/user/login`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						uid: userEmail,
						password: userPassword,
						auth_type: 'local',
					}),
				}).then((r) => {
					const jwt = r.data.jwt_token;
					for (let index in agreeTerms) {
						requestUtil(`/term/agree`, {
							method: 'POST',
							headers: { 'Content-Type': 'application/json', 'bayabas': jwt },
							body: JSON.stringify({
								term_id: agreeTerms[index],
								is_agreement: true,
							}),
						}).then();
					}
				});
			});
		}

		setTimeout(
			() => {
				switch (pageIndex) {
					case 1:
						return setThisIndexElement(
							<SignUpEmailContents setPageIndex={setPageIndex} setUserEmail={setUserEmail} />
						);
					case 2:
						return setThisIndexElement(
							<SignUpEmailSendContents userInputEmail={userEmail} setPageIndex={setPageIndex} />
						);
					case 3:
						return setThisIndexElement(
							<SignUpPasswordContents
								setUserPassword={setUserPassword}
								setPageIndex={setPageIndex}
							/>
						);
					case 4:
						return setThisIndexElement(
							<SignUpNicknameContents
								setUserNickname={setUserNickname}
								setPageIndex={setPageIndex}
							/>
						);
					case 5:
						return setThisIndexElement(
							<SignUpTermContents setAgreeTerms={setAgreeTerms} setPageIndex={setPageIndex} />
						);
					case 6:
						return setThisIndexElement(<SignUpDoneContain />);
				}
			},
			pageIndex === 1 ? 0 : 200
		);
		// eslint-disable-next-line
	}, [pageIndex]);

	return (
		<div className={pageTransition + ' w-full min-h-screen grid place-items-center sm:px-3'}>
			<div>
				{pageIndex === 6 ? (
					<h1 className={'text-3xl font-semibold'}>
						바야바즈
						<br />
						회원가입 완료 🎉🎉
					</h1>
				) : (
					<h1 className={'text-3xl font-semibold mt-3'}>
						바야바즈에
						<br />
						오신 걸 환영합니다.
					</h1>
				)}
				<p className={'my-8 text-gray-800 font-thin'}>
					바야바즈 웹 회원가입 시 <br />
					바야바즈 앱에서도 해당 아이디 사용이 가능합니다. <br />
					진단 기록 및 결과 또한 연동됩니다.
				</p>
				{thisIndexElement}
				<br />
			</div>
		</div>
	);
}
