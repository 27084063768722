import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import requestUtil from '../../utils/request.util';
import { UserType } from '../../types/user.type';
import LoadingContain from '../../containers/base/loading.contain';

export default function LogInPage(): ReactElement {
	const [Email, setEmail] = useState<string>('');
	const [Password, setPassword] = useState<string>('');
	// 이메일 혹은 비밀번호가 틀릴경우 alert 출력을 위한 변수
	const [alert, setAlert] = useState<string>('');
	// 로그인 처리 시 대기시간 딤처리
	const [isLoading, setIsLoading] = useState<boolean>(false);
	// 비밀번호 틀림 카운트
	const [idiotCount, setIdiotCount] = useState<number>(0);

	const onEmailHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setEmail(e.currentTarget.value);
	};
	const onPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setPassword(e.currentTarget.value);
	};
	// 로그인 처리 함수
	const login = () => {
		// 로딩 on
		setIsLoading(true);
		// 기존에 있던 로컬 데이터 삭제
		localStorage.clear();
		// 로그인 API 호출
		requestUtil(`/user/login`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				uid: Email,
				password: Password,
				auth_type: 'local',
			}),
		}).then((r) => {
			// 401 = 없는 유저 || 비밀번호 틀림
			if (r.status === 401) {
				setIsLoading(false);
				setIdiotCount(idiotCount + 1);
				setAlert(
					idiotCount < 2
						? '이메일 혹은 비밀번호가 맞지 않습니다.'
						: '비밀번호는 바야바즈 앱에서 찾으실 수 있습니다.'
				);
				// 로그인 성공
				// localStorage jwt 토큰 저장 및 유저정보 저장
			} else {
				const jwtToken = r.data.jwt_token;
				const expireDate = new Date().getTime() + 24 * 60 * 60 * 1000; //
				localStorage.setItem('login', JSON.stringify({ jwtToken, expireDate }));
				requestUtil(`/user/my`, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json', 'bayabas': jwtToken },
				}).then((r) => {
					const userModel = new UserType();
					userModel.setUser(r.data);
					localStorage.setItem('user', JSON.stringify(userModel));
					document.location.href = '/';
				});
			}
		});
	};
	// enter listener : 비밀번호 Enter 키보드 리스너
	const enterListener = (e: any) => {
		setAlert('');
		if (e.key === 'Enter') {
			login();
		}
	};
	return (
		<div className={'min-h-screen grid items-center sm:px-3'}>
			{/* 로그인 로딩 딤처리 start*/}
			{isLoading && <LoadingContain message={'로그인 중 입니다. ☺️'} />}
			{/* 로그인 로딩 딤처리 end*/}
			<div className={'w-full'}>
				<h1 className={'text-3xl font-semibold mb-8'}>
					바야바즈를
					<br /> 통합 계정으로 시작해보세요!
				</h1>
				<div className={'flex flex-col text-sm font-light'}>
					<input
						className={'py-4 px-5'}
						type="email"
						placeholder={'이메일을 입력해주세요.'}
						value={Email}
						onChange={onEmailHandler}
					/>
					<input
						className={'py-4 px-5 my-3'}
						type="password"
						placeholder={'비밀번호를 입력해주세요.'}
						value={Password}
						onChange={onPasswordHandler}
						onKeyPress={enterListener}
					/>
					<div className={'flex items-center'}>
						<span className={'text-red-400'}>{alert}</span>
						{/*<Link to={''} className={'ml-auto'}>*/}
						{/*	비밀번호 찾기*/}
						{/*</Link>*/}
					</div>
					<p className={'text-center my-8 text-gray-800'}>
						아직 회원이 아니신가요?
						<br />
						<Link to={'signup'} className={'underline font-medium'}>
							회원가입
						</Link>
					</p>
					<button
						onClick={login}
						onSubmit={login}
						className={'bg-green-500 py-4 text-white font-medium'}
					>
						시작하기
					</button>
				</div>
			</div>
		</div>
	);
}
