import { useState, useEffect, ReactElement } from 'react';
import { DiagnosisCategory } from '../../types/diagnosis/diagnosis-category.type';
import requestUtil from '../../utils/request.util';
import DiagnosisCategoryContain from '../../containers/diagnosis/diagnosis-category.contain';

/**
 *  @description 진단 카테고리 훅으로 진단의 카테고리와 그와 연결되어 있는 진단을 묶어서 반환합니다.
 *  @return DiagnosisCategoryContain
 */
export function DiagnosisCategoryHook(): ReactElement {
	const [categoryDiagnosis, setCategoryDiagnosis] = useState<Array<DiagnosisCategory> | undefined>(
		undefined
	);
	const [loading, setLoading] = useState<Boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);

	const fetchDiagnosis = async () => {
		try {
			setError(undefined);
			setCategoryDiagnosis(undefined);
			setLoading(true);
			const response = await requestUtil(`/diagnosis`, {
				method: 'GET',
			});
			setCategoryDiagnosis(response.data);
		} catch (e: any) {
			setError(e);
		}
		// setLoading(false);
		setTimeout(() => setLoading(false), 300);
	};

	useEffect(() => {
		fetchDiagnosis().then();
		/* eslint-disable */
	}, []);
	if (loading)
		return (
			<>
				<DiagnosisCategoryContain diagnosisCategory={null} />
			</>
		);
	if (error)
		return (
			<>
				<div>{error.toString()}</div>
				<DiagnosisCategoryContain diagnosisCategory={null} fetchFn={fetchDiagnosis} />
			</>
		);
	if (!categoryDiagnosis) return <>null</>;
	return (
		<>
			{categoryDiagnosis.map((e, k) => (
				<DiagnosisCategoryContain key={k} diagnosisCategory={e} />
			))}
		</>
	);
}
