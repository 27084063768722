import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export default function PinkdaDiagnosisIntroPage(): ReactElement {
	return (
		<div className={'w-full grid place-items-center h-screen'} style={{ minWidth: '300px' }}>
			<div className={'content-center place-items-center w-1/3 sm:w-11/12 sm:py-3'}>
				<img
					className={'w-9/12 m-auto'}
					src={require('../../../../assets/images/ppinping.png')}
					alt="logo"
				/>
				<div
					className="
				              text-3xl sm:text-xl
				              "
				>
					바야바즈!
				</div>
				<div
					className="
				              font-bold text-3xl mb-3 sm:text-xl
				              "
				>
					내 두피 상태를 알려줘!
				</div>
				<div
					className="
                text-lg leading-7 sm:text-sm
              "
				>
					바야바즈와 핑크다가 제공하는 두피 진단은
					<br />
					유전적인 요인, 생활패턴, 스트레스, 두피타입 등을
					<br />
					파악하여, 현재 두피의 상태와 건강을 진단해요.
				</div>
				<div
					className="
                font-light leading-5 sm:text-xs text-gray-500
                mb-8 mt-5
              "
				>
					두피 질병 및 질환은 의료기관을 방문 해 주세요.
				</div>
				<Link to={`/diagnosis/list`}>
					<button
						style={{
							height: '48px',
							backgroundColor: '#3e3e3e',
						}}
						className="
                              w-full
                              border-2 border-slate-600 p-2
                              bg-slate-600
                              text-white text-sm
                          "
					>
						진단하기
					</button>
				</Link>
				<button
					style={{
						border: 'solid 1px hotpink',
						height: '48px',
					}}
					onClick={() =>
						window.open('https://www.pinkda.kr/goods/goods_list.php?cateCd=026', '_parent')
					}
					className="
                          w-full mt-2 bg-white
                          text-pink-600 text-sm
                      "
				>
					핑크다 추천제품 보기
				</button>
				<button
					style={{
						border: 'solid 1px #696969',
						height: '48px',
					}}
					onClick={() => window.open('https://neurocircuit.kr/service/', '_parent')}
					className="
                          w-full mt-2
                          bg-white
                          font-medium text-slate-700 text-sm
                      "
				>
					바야바즈 앱 바로가기
				</button>
			</div>
		</div>
	);
}
