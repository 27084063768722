import { useState, useEffect, ReactElement } from 'react';
import requestUtil from '../../utils/request.util';
import { AuthTermType } from '../../types/auth/auth-term.type';
import AuthTermCard from '../../constants/auth/auth-term.card';

export function AuthTermHook(props: {
	setAgreeTerms: Function;
	setPageIndex: Function;
}): ReactElement {
	const [authTermArr, setAuthTermArr] = useState<Array<AuthTermType> | undefined>(undefined);
	const [loading, setLoading] = useState<Boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);

	const fetchDiagnosis = async () => {
		try {
			setError(undefined);
			setAuthTermArr(undefined);
			setLoading(true);
			const response = await requestUtil(`/term`, {
				method: 'GET',
			});
			setAuthTermArr(response.data);
		} catch (e: any) {
			setError(e);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchDiagnosis().then();
		/* eslint-disable */
	}, []);
	if (loading) return <></>;
	if (error) return <></>;
	if (!authTermArr) return <>null</>;
	return (
		<>
			<AuthTermCard
				setPageIndex={props.setPageIndex}
				setAgreeTerms={props.setAgreeTerms}
				authTermArr={authTermArr}
			/>
		</>
	);
}
