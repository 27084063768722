import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { DiagnosisItemHook } from '../../hooks/diagnosis/diagnosis-item.hook';

export default function DiagnosisItemPage(props: { isLogin: boolean }): ReactElement {
	const { id } = useParams();

	return (
		<>
			<div className="container max-w-xl min-h-screen grid place-items-center py-24 sm:py-3">
				<div>
					<DiagnosisItemHook diagnosisId={id!.toString()} />
				</div>
			</div>
		</>
	);
}
