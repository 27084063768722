import requestUtil from '../../../utils/request.util';
import { UserType } from '../types/user.type';

const login = async () => {
	const serverHost = process.env.REACT_APP_BASE_SERVER_HOST;
	const adminId = process.env.REACT_APP_BAYABAS_ADMIN_ID;
	const adminPw = process.env.REACT_APP_BAYABAS_ADMIN_PW;
	return await requestUtil(`${serverHost}/user/login`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			uid: adminId,
			password: adminPw,
			auth_type: 'local',
			fcm_token: null,
		}),
	}).then((response) => {
		if (response.data.jwt_token) {
			localStorage.setItem('login', response.data.jwt_token);
		}
	});
};

const logout = () => {
	localStorage.clear();
	document.location.href = '/';
};

const getCurrentUserToken = () => {
	const userToken = localStorage.getItem('login') ?? null;
	if (userToken) {
		return JSON.parse(userToken);
	} else {
		return null;
	}
};
const getCurrentUserInfo = (): UserType | null => {
	const localUserData = localStorage.getItem('user') ?? null;
	if (localUserData) {
		const userInfoModel = new UserType();
		userInfoModel.setUser(JSON.parse(localUserData));
		return userInfoModel;
	} else {
		return null;
	}
};

const AuthService = {
	login,
	logout,
	getCurrentUser: getCurrentUserToken,
	getCurrentUserInfo,
};

export default AuthService;
