import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export default function DiagnosisEmptyContain(): ReactElement {
	return (
		<div className={'min-h-screen grid place-items-center sm:block'}>
			<div>
				<img
					src={require('../../assets/images/ouch.png')}
					alt={'ouch'}
					className={'scale-75 m-auto mt-16'}
				/>
				<h1 className={'text-xl text-center'}>앗, 아직 진단을 하지 않으셨네요.</h1>
				<p className={'text-center text-center mt-3 text-sm mb-8'}>
					바야바즈 진단을 통해 나의 두피 상태와
					<br />
					컨디션에 따른 맞춤 추천을 받아보세요!
				</p>
				<Link to={`/diagnosis/list`}>
					<button
						style={{
							backgroundColor: '#3e3e3e',
							height: '48px',
						}}
						className={`
							w-full mt-2
							bg-white font-medium text-white text-sm`}
					>
						진단하기
					</button>
				</Link>
				<Link to={`/auth`}>
					<button
						style={{
							border: 'solid 1px #696969',
							height: '48px',
						}}
						className={`
							w-full mt-2
							bg-white font-medium text-slate-700 text-sm`}
					>
						로그인 하기
					</button>
				</Link>
			</div>
		</div>
	);
}
