import React, { ReactElement } from 'react';
import { SolutionType } from '../../types/solution/solution.type';

export default function RecommendedSolutionCard(props: { solution: SolutionType }): ReactElement {
	return (
		<>
			<h1 className={'m-auto px-10 text-left text-2xl font-bold sm:text-center'}>
				{props.solution.solution_title}
			</h1>
			<img
				src={props.solution.solution_image_url}
				alt={'recommended_title'}
				className={'w-1/3 m-auto my-10'}
			/>
			<p className={'px-10 text-gray-500'}>
				{props.solution.solution_content.length < 50
					? props.solution.solution_content
					: props.solution.solution_content.slice(0, 80) + ' ...'}
			</p>
		</>
	);
}
