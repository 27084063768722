import React, { ReactElement } from 'react';

export default function LoadingContain(props: { message: string }): ReactElement {
	return (
		<>
			{/* 로그인 로딩 딤처리 start*/}
			<div
				className={`w-full h-full z-50 fixed top-0 left-0 grid items-center opacity-75`}
				style={{
					backgroundColor: '#EDF2F5',
				}}
			>
				<div>
					<img
						alt={'loading-img'}
						src={require('../../assets/images/loading.gif')}
						className={'w-32 m-auto'}
					/>
					<p className={'text-center mt-3'}>LOADING...</p>
					<p className={'text-center mt-6 text-xl p-3'}>{props.message}</p>
				</div>
			</div>
			{/* 로그인 로딩 딤처리 end*/}
		</>
	);
}
