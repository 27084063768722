import React, { ReactElement, useEffect, useState } from 'react';
import requestUtil from '../../utils/request.util';

export default function SignUpEmailSendContents(props: {
	userInputEmail: String;
	setPageIndex: Function;
}): ReactElement {
	const [authText, setAuthText] = useState<string | null>(null);
	const [isAuthCheck, setIsAuthCheck] = useState<boolean>(false);
	const [isAlert, setIsAlert] = useState<string>('hidden');
	const [inputText, setInputText] = useState<string>('');
	const [limitTime, setLimitTime] = useState<number>(60);
	const onInputTextHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setIsAlert('hidden');
		setIsAuthCheck(false);
		setInputText(e.currentTarget.value);
	};
	const sendAuthMail = async (): Promise<void> => {
		const response = await requestUtil(`/user/send/authmail`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				email: props.userInputEmail,
			}),
		});
		await setAuthText(response.data.authNumber);
	};
	const next = (): void => {
		if (authText === inputText) {
			setIsAlert('hidden');
			setIsAuthCheck(true);
			props.setPageIndex(3);
		} else {
			setIsAlert('');
			setIsAuthCheck(false);
		}
	};
	const pre = (): void => {
		props.setPageIndex(1);
	};
	const reSend = (): void => {
		sendAuthMail().then();
		setIsAlert('hidden');
		setIsAuthCheck(false);
		setLimitTime(60);
	};
	useEffect(() => {
		sendAuthMail().then();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const timer = limitTime > 0 && setInterval(() => setLimitTime(limitTime - 1), 1000);
		limitTime === 0 && setIsAlert('');
		setIsAuthCheck(false);
		// @ts-ignore
		return () => clearInterval(timer);
	}, [limitTime]);
	return (
		<div className={'flex flex-col text-sm'}>
			<p className={limitTime === 0 ? 'text-red-400' : 'text-green-500'}>
				00:{(limitTime < 10 ? '0' : '') + limitTime.toString()}
			</p>
			<input
				className={'py-4 px-5 my-2'}
				type="text"
				placeholder={'인증번호를 입력 해 주세요.'}
				value={inputText}
				onChange={onInputTextHandler}
			/>
			<div className={'flex items-center'}>
				<p className={'text-red-400 ' + isAlert}>
					{limitTime !== 0 ? '인증문자를 정확하게 입력해 주세요.' : '인증 시간이 초과되었습니다.'}
				</p>
				<p className={'text-green-400 ' + (!isAuthCheck && 'hidden')}>인증되었습니다.</p>
				<button onClick={reSend} className={'flex ml-auto text-gray-800 font-light'}>
					인증번호 재전송
				</button>
			</div>
			<button
				onClick={pre}
				className={'mt-10 py-4 text-gray-700 border-gray-400'}
				style={{
					border: '1px solid',
				}}
			>
				이메일 재입력
			</button>
			<button onClick={next} className={'mt-1 py-4 text-white bg-green-500'}>
				인증 완료
			</button>
		</div>
	);
}
