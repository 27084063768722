import React, { ReactElement, useState } from 'react';
import requestUtil from '../../utils/request.util';

export default function SignUpNicknameContents(props: {
	setUserNickname: Function;
	setPageIndex: Function;
}): ReactElement {
	const [nickname, setNickname] = useState<string>('');
	const [alert, setAlert] = useState<string | null>(null);
	const onNicknameHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setAlert(null);
		setNickname(e.currentTarget.value);
	};
	const focusOutEmailCheck = (): void => {
		const validMail: boolean = validNickname();
		if (validMail) emailUseCheck().then();
	};
	const validNickname = (): boolean => {
		// eslint-disable-next-line
		const pattern = /^(?=.*[a-z\d가-힣])[a-z\d가-힣]{2,16}$/;
		return nickname.match(pattern) != null;
	};
	const emailUseCheck = async (): Promise<void> => {
		const response = await requestUtil(`/user/use/check/nickname`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				value: nickname,
			}),
		});
		const emailUse: boolean = await response.data.use;
		setAlert(emailUse ? '' : '이미 사용중인 닉네임 입니다.');
	};
	const next = (): void => {
		if (alert === '') {
			props.setPageIndex(5);
			props.setUserNickname(nickname);
		}
	};
	return (
		<div className={'flex flex-col text-sm'}>
			<div className={'relative'}>
				<input
					className={'py-4 px-5 w-full'}
					placeholder={'닉네임을 입력 해 주세요.'}
					value={nickname}
					onChange={onNicknameHandler}
				></input>
				<button
					onClick={focusOutEmailCheck}
					className={`
					  h-full 
						absolute right-0 pr-3
						font-medium text-slate-600
					`}
				>
					중복검사
				</button>
			</div>

			<div className={'flex items-center my-3'}>
				<span
					className={
						(alert !== '' ?? ' hidden') + (alert !== '' ? ' text-red-400' : ' text-green-500')
					}
				>
					{alert !== '' ? alert : '사용 가능한 닉네임 입니다.'}
				</span>
			</div>

			<button
				onClick={next}
				className={
					'mt-10 py-4 text-white' +
					(alert !== '' ? ' bg-gray-500 cursor-not-allowed' : ' bg-green-500')
				}
				disabled={alert !== ''}
			>
				닉네임 설정 완료
			</button>
		</div>
	);
}
