import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export default function SignUpDoneContain(): ReactElement {
	return (
		<>
			<Link to={'/auth'}>
				<button
					className={'w-full mt-5 py-4 text-gray-700 border-gray-400'}
					style={{
						border: '1px solid',
					}}
				>
					로그인 하기
				</button>
			</Link>
		</>
	);
}
