import React, { ReactElement, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import DiagnosisIntroPage from './pages/diagnosis/diagnosis-intro.page';
import DiagnosisListPage from './pages/diagnosis/diagnosis-list.page';
import DiagnosisItemPage from './pages/diagnosis/diagnosis-item.page';
import DiagnosisResultPage from './pages/diagnosis/diagnosis-result.page';
import NotFound from './pages/not-found.page';
import { toast, ToastContainer } from 'react-toastify';
import LogInPage from './pages/auth/log-in.page';
import SignUpPage from './pages/auth/sing-up.page';
import DiagnosisRecommendedPage from './pages/diagnosis/diagnosis-recommended.page';
import PinkdaDiagnosisIntroPage from './collaborate/pinkda/pages/diagnosis/pinkda-diagnosis-intro.page';
import PinkdaDiagnosisItemPage from './collaborate/pinkda/pages/diagnosis/pinkda-diagnosis-item.page';
import PinkdaDiagnosisResultPage from './collaborate/pinkda/pages/diagnosis/pinkda-diagnosis-result.page';
import PinkdaDiagnosisRecommendedPage from './collaborate/pinkda/pages/diagnosis/pinkda-diagnosis-recommended.page';

export default function App(): ReactElement {
	const [isLogin, setIsLogin] = useState(false);
	// @ts-ignore
	if (!Kakao.isInitialized()) {
		// @ts-ignore
		Kakao.init(process.env.REACT_APP_KAKAO_KEY);
	}
	useEffect(() => {
		const loginCheck = (): void => {
			const localData = localStorage.getItem('login');
			try {
				const auth = localData ? JSON.parse(localData) : null;
				if (auth !== null) {
					const currentDate = new Date().getTime();
					if (auth.expireDate < currentDate) {
						localStorage.clear();
						toast.error('장시간 이용하지 않아 로그아웃 되었습니다.');
						document.location.href = '/';
						setIsLogin(false);
					} else {
						setIsLogin(true);
					}
				} else {
					setIsLogin(false);
				}
			} catch (e: any) {
				e.name === 'SyntaxError' && localStorage.removeItem('login');
				loginCheck();
			}
		};
		loginCheck();
		/* eslint-disable */
	});

	return (
		<>
			<div>
				<Routes>
					<Route path="collaborate">
						<Route path="pinkda">
							<Route index element={<PinkdaDiagnosisIntroPage />} />
							<Route path="diagnosis">
								<Route index element={<PinkdaDiagnosisIntroPage />} />
								<Route path="item/:id" element={<PinkdaDiagnosisItemPage isLogin={isLogin} />} />
								<Route path="result" element={<PinkdaDiagnosisResultPage />} />
								<Route path="result/recommended" element={<PinkdaDiagnosisRecommendedPage />} />
							</Route>
						</Route>
					</Route>
					<Route path="diagnosis">
						<Route index element={<DiagnosisIntroPage />} />
						<Route path="list" element={<DiagnosisListPage />} />
						<Route path="item/:id" element={<DiagnosisItemPage isLogin={isLogin} />} />
						<Route path="result" element={<DiagnosisResultPage />} />
						<Route path="result/recommended" element={<DiagnosisRecommendedPage />} />
					</Route>
					<Route path="auth">
						<Route index element={<LogInPage />} />
						<Route path={'signup'} element={<SignUpPage />} />
					</Route>
					<Route path="/" element={<DiagnosisIntroPage />} />
					<Route path="/*" element={<NotFound />} />
				</Routes>
				<ToastContainer
					position="bottom-center"
					autoClose={500}
					hideProgressBar={false}
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					limit={3}
				/>
			</div>
		</>
	);
}
