function parseJSON(response: any) {
	if (response.status === 204 || response.status === 205) {
		return null;
	}
	return response.json();
}
function checkStatus(response: any) {
	return response;
}
export default async function requestUtil(url: string, options?: any | undefined) {
	try {
		const serverHost = process.env.REACT_APP_BASE_SERVER_HOST;
		return await fetch(serverHost + url, options)
			.then(checkStatus)
			.then(parseJSON);
	} catch (e: any) {
		console.log(e);
	}
}
