import React, { ReactElement, useEffect, useState } from 'react';
import { SolutionArrayType } from '../../types/solution/solutionArray.type';
import RecommendedSolutionCard from '../../constants/solution/recommended-solution.card';

export default function RecommendedSolutionContain(props: {
	isLoading?: Boolean;
	solutions: SolutionArrayType;
}): ReactElement {
	const [solutionIndex, setSolutionIndex] = useState<number>(0);
	const maxIndex = props.solutions.recommendedSolutions.length - 1;

	const nextIndex = () => {
		if (solutionIndex < maxIndex) {
			changeIndex(solutionIndex + 1);
		} else {
			changeIndex(0);
		}
	};

	const changeIndex = (index: number) => {
		const targetBtn = document.getElementById(index.toString());
		setSolutionIndex(index);
		targetBtn && targetBtn.classList.add('bg-green-500');
	};
	useEffect(() => {
		setTimeout(() => {
			nextIndex();
		}, 2000);
		// eslint-disable-next-line
	}, [solutionIndex]);

	return (
		<>
			<div
				className={
					'relative solution-wrap mt-10 relative mx-3 my-3 rounded-xl py-14 overflow-hidden hidden sm:block'
				}
				style={{
					boxShadow: '3px 3px 10px 0.1px rgb(0 0 0 / 0.08)',
					height: '480px',
				}}
			>
				{props.solutions.recommendedSolutions.length !== 0 ? (
					<RecommendedSolutionCard solution={props.solutions.recommendedSolutions[solutionIndex]} />
				) : (
					<div className={'mt-3 text-center text-2xl font-bold pt-16'}>
						아쉽지만 추천되는
						<br />
						관리법이 아직 없어요. 😭
					</div>
				)}
			</div>
			<div className={'grid grid-cols-3 gap-12 sm:hidden mt-12'}>
				{props.solutions.recommendedSolutions.map((e, k) => {
					return (
						<div className={'py-10 px-3 rounded-xl bg-white'} key={k}>
							<RecommendedSolutionCard solution={e} />
						</div>
					);
				})}
			</div>
			<div className={'indicatorWrap m-auto text-center hidden sm:block'}>
				<button
					className={
						'rounded-3xl h-2 mr-2 ' +
						(solutionIndex === 0 ? ' bg-green-500 w-4 ' : ' bg-gray-500 w-2 ') +
						(parseInt(maxIndex.toString()) < 0 ? ' hidden' : '')
					}
				></button>
				<button
					className={
						'rounded-3xl h-2 mr-2 ' +
						(solutionIndex === 1 ? ' bg-green-500 w-4 ' : ' bg-gray-500 w-2 ') +
						(parseInt(maxIndex.toString()) < 1 ? ' hidden ' : '')
					}
				></button>
				<button
					className={
						'rounded-3xl h-2 mr-2 ' +
						(solutionIndex === 2 ? ' bg-green-500 w-4 ' : ' bg-gray-500 w-2 ') +
						(parseInt(maxIndex.toString()) < 2 ? ' hidden ' : '')
					}
				></button>
			</div>
		</>
	);
}
