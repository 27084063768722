import { useState, useEffect } from 'react';
import requestUtil from '../../../../utils/request.util';
import authUtil from '../../utils/auth.util';
import DiagnosisResultCardContain from '../../containers/diagnosis/diagnosis-result-card.contain';
import { DiagnosisResultGraphType } from '../../types/diagnosis/diagnosis-result-graph.type';
import { DiagnosisResultArrayType } from '../../types/diagnosis/diagnosis-result-array.type';
import DiagnosisEmptyContain from '../../containers/diagnosis/diagnosis-empty.contain';

export function DiagnosisResultHook(props: { sendData?: any }) {
	const [DxResultArrClass, setDxResultArrClass] = useState<DiagnosisResultArrayType | undefined>(
		undefined
	);
	const [DxGraph, setDxGraph] = useState<DiagnosisResultGraphType | undefined>(undefined);
	const [loading, setLoading] = useState<Boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);
	const userToken = authUtil.getCurrentUser();

	const saveDiagnosis = async (): Promise<void> => {
		if (props.sendData) {
			const response = await requestUtil(`${userToken ? '/diagnosis' : '/diagnosis/none/user'}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'bayabas': userToken ? userToken.jwtToken : '',
				},
				body: JSON.stringify(props.sendData),
			});
			response.data &&
				localStorage.setItem(`diagnosis_${props.sendData.diagnosis_id}`, response.data.dx_log_id);
			if (response.success === false) {
				await setError(new Error(response.message));
			}
		}
	};
	const getDxResult = async (): Promise<void> => {
		const dxLogId_1 = localStorage.getItem('diagnosis_1');
		if (userToken || dxLogId_1) {
			const response = await requestUtil(
				`${userToken ? '/diagnosis/result/0' : '/diagnosis/none/user/result/0'}`,
				{
					method: userToken ? 'GET' : 'POST',
					headers: userToken
						? { bayabas: userToken.jwtToken }
						: { 'Content-Type': 'application/json' },
					body:
						dxLogId_1 &&
						JSON.stringify({
							dx_log_id: dxLogId_1,
						}),
				}
			);
			if (response.success === false) {
				await setError(response.message);
			} else {
				await setDxResultArrClass(new DiagnosisResultArrayType(response.data));
			}
		} else {
			setDxResultArrClass(undefined);
		}
	};
	const getDxResultGraph = async (): Promise<void> => {
		const dxLogId_1 = localStorage.getItem('diagnosis_1');
		if (userToken || dxLogId_1) {
			const response = await requestUtil(
				`${userToken ? '/diagnosis/result/graph' : '/diagnosis/none/user/graph'}`,
				{
					method: userToken ? 'GET' : 'POST',
					headers: userToken
						? { bayabas: userToken.jwtToken }
						: { 'Content-Type': 'application/json' },
					body:
						dxLogId_1 &&
						JSON.stringify({
							dx_log_id: dxLogId_1,
						}),
				}
			);
			if (response.success === false) {
				await setError(response.message);
			} else {
				response.data.category = JSON.parse(response.data.category);
			}
			await setDxGraph(
				new DiagnosisResultGraphType(response.data.category, JSON.parse(response.data.after_score))
			);
		} else {
			setDxGraph(undefined);
		}
	};
	useEffect(() => {
		setLoading(true);
		const dxSaveAndGetResult = async (): Promise<void> => {
			await saveDiagnosis();
			await getDxResult();
			await getDxResultGraph();
		};
		dxSaveAndGetResult().then(() => setTimeout(() => setLoading(false), 300));
		/* eslint-disable */
	}, []);
	if (loading)
		return (
			<>
				<DiagnosisResultCardContain loading={true} />
			</>
		);
	if (error) return <>에러</>;
	if (!DxResultArrClass || !DxGraph) return <DiagnosisEmptyContain />;
	return (
		<>
			<DiagnosisResultCardContain
				loading={false}
				dxResultArr={DxResultArrClass}
				dxGraph={DxGraph}
			/>
		</>
	);
}
