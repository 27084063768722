import React, { ReactElement } from 'react';
import { DiagnosisCategoryHook } from '../../hooks/diagnosis/diagnosis-category.hook';

export default function DiagnosisListPage(): ReactElement {
	return (
		<div
			className="p-8 sm:p-2 sm:w-screen mt-16 sm:mt-0 min-h-screen"
			style={{
				maxWidth: '980px',
			}}
		>
			<DiagnosisCategoryHook />
			<div
				className="
                        button-wrap
                        mt-12 sm:mt-5
                        sm:p-10 sm:pt-0
                        "
			></div>
		</div>
	);
}
