import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function FooterContain(): ReactElement {
	const path = useLocation().pathname;
	const [isCollaboratePath, setIsCollaboratePath] = useState<boolean>(false);
	useEffect(() => {
		setIsCollaboratePath(!!path.match('collaborate'));
	}, [path]);
	return (
		<>
			<div className={'sm:hidden'} style={{ display: 'none' }}>
				<div
					className={`sm:px-3 place-items-center grid ${isCollaboratePath ? 'hidden' : ''}`}
					style={{
						backgroundColor: '#2F2F2F',
						height: '300px',
					}}
				>
					<div
						className={'text-gray-300 leading-6'}
						style={{
							minWidth: '900px',
						}}
					>
						<img
							src={require('../../assets/images/newro_logo.png')}
							className={'w-44 mb-8'}
							alt={'logo'}
						/>
						<p className={'font-medium mb-3'}>주식회사 뉴로서킷</p>
						<p>대표 : 이석진(Seock Jin Lee)</p>
						<p>
							본사 주소 : 부산광역시 해운대구 센텀동로 45, 621호(우동, (주)웹스)
							<br />
							연구소 주소 : 경기도 고양시 일산동구 호수로 646-30 406-3호
							<br />
							이메일 : sjlee@bayabas.kr
							<br />
						</p>
						<p>ⓒ2022 NEUROCIRCUIT. All Rights Reserved.</p>
					</div>
				</div>
			</div>

			{/* app view */}
			<div className={'hidden sm:block'} style={{ display: 'none' }}>
				<div
					className={`sm:px-3 py-12 place-items-center grid ${isCollaboratePath ? 'hidden' : ''}`}
					style={{
						backgroundColor: '#2F2F2F',
					}}
				>
					<div className={'text-gray-300 text-sm hidden sm:block'}>
						<img
							src={require('../../assets/images/newro_logo.png')}
							className={'w-44 mb-8'}
							alt={'logo'}
						/>
						<p className={'font-medium mb-3'}>주식회사 뉴로서킷</p>
						<p>대표 : 이석진(Seock Jin Lee)</p>
						<p>
							본사 주소 : 부산광역시 해운대구 센텀동로 45, 621호(우동, (주)웹스)
							<br />
							연구소 주소 : 경기도 고양시 일산동구 호수로 646-30 406-3호
							<br />
							이메일 : sjlee@bayabas.kr
							<br />
						</p>
						<p>ⓒ2022 NEUROCIRCUIT. All Rights Reserved.</p>
					</div>
				</div>
			</div>
		</>
	);
}
