import React, { ReactElement } from 'react';
import { Diagnosis } from '../../types/diagnosis/diagnosis.type';
import { FiEye, FiClock, FiGithub } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import DiagnosisCardLoader from './loader/dianosis-card.loader';
import Error from '../error';
import moment from 'moment';

export default function DiagnosisListCard(props: {
	diagnosis: Diagnosis | null;
	fetchFn?: Function;
}): ReactElement {
	return (
		// <Link
		// 	to={
		// 		props.diagnosis && !props.diagnosis.open_date ? `/diagnosis/item/${props.diagnosis.id}` : ''
		// 	}
		// >
		<Link to={props.diagnosis ? `/diagnosis/item/${props.diagnosis.id}` : ''}>
			<button
				className={
					'mt-8 sm:px-6 bg-white shadow-md w-full hover:shadow-gray-400 rounded-3xl px-12 py-6 text-left grid grid-cols-5 sm:grid-cols-none relative overflow-hidden'
				}
			>
				{props.fetchFn ? <Error fetchFn={props.fetchFn} /> : false}
				{props.diagnosis ? (
					<>
						{!props.diagnosis.open_date ? (
							<></>
						) : (
							<>
								<div
									className={
										'grid text-center text-white place-items-center absolute w-full h-full bg-black opacity-70 text-xl'
									}
								>
									지금 '바야바즈' 앱에서 바로 진단이 가능합니다!
									<p className="text-lg grid absolute top-40">
										(PC버전 오픈 {moment(props.diagnosis.open_date).format('YYYY년 MM월 ')} 예정 🎉)
									</p>
								</div>
							</>
						)}
						<img
							src={`${props.diagnosis.decoration_thumbnail}`}
							alt={'decoration_thumbnail'}
							className={'w-1/2 m-auto hidden mb-3 sm:block'}
						/>
						<div className={'col-span-4'}>
							<div
								className={
									'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 w-20 h-1 mt-1'
								}
								style={{
									background: `linear-gradient(to right, ${props.diagnosis.background_color_left}, ${props.diagnosis.background_color_right})`,
								}}
							></div>
							<div className={'text-gray-800 text-xl font-medium mt-3'}>
								{props.diagnosis.title}
							</div>
							<div className={'text-lg font-light py-1'}>{props.diagnosis.sub_title}</div>
							<div className={'mb-5 font-thin text-black'}>{props.diagnosis.description}</div>
							<div className={'text-gray-600 font-light inline-block'}>
								<FiEye className={'inline-block'} /> {props.diagnosis.view_count!.toString()}
								<FiClock className={'inline-block ml-3'} />{' '}
								{props.diagnosis.required_time!.toString()}m
								<FiGithub className={'inline-block ml-3'} />
							</div>
						</div>
						<img
							src={`${props.diagnosis.decoration_thumbnail}`}
							alt={'decoration_thumbnail'}
							className={'w-full p-10 m-auto sm:hidden'}
						/>
					</>
				) : (
					<>
						<div className={'sm:w-11/12 sm:overflow-hidden'}>
							<DiagnosisCardLoader />
						</div>
					</>
				)}
			</button>
		</Link>
	);
}
