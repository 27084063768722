import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { AuthTermType } from '../../types/auth/auth-term.type';
import { FiCircle, FiCheckCircle } from 'react-icons/fi';

export default function AuthTermCard(props: {
	authTermArr: Array<AuthTermType>;
	setAgreeTerms: Function;
	setPageIndex: Function;
}): ReactElement {
	const [checkItems, setCheckItems] = useState<Array<number>>([]);
	const [alert, setAlert] = useState<string | null>(null);
	let requireTerms: Array<number> = [];
	const onCheckedElement = useCallback(
		(e: any) => {
			const id: number = +e.target.id;
			if (!checkItems.includes(id)) {
				setCheckItems([...checkItems, id]);
			} else {
				setCheckItems(checkItems.filter((e) => e !== id));
			}
		},
		// eslint-disable-next-line
		[checkItems]
	);
	const onCheckedAll = useCallback(() => {
		if (props.authTermArr.length !== checkItems.length) {
			const checkedListArray: Array<number> = [];
			props.authTermArr.forEach((e: AuthTermType) => checkedListArray.push(e.id));
			setCheckItems(checkedListArray);
		} else {
			setCheckItems([]);
		}
		// eslint-disable-next-line
	}, [checkItems]);
	const next = (): void => {
		if (checkRequireTerms()) {
			setAlert(' ');
			props.setAgreeTerms(checkItems.filter((e) => e !== 0));
			props.setPageIndex(6);
		} else {
			props.setAgreeTerms([]);
			setAlert('필수항목을 체크해 주세요.');
		}
	};

	const checkRequireTerms = (): boolean => {
		for (let e of requireTerms) {
			if (!checkItems.includes(e)) {
				return false;
			}
		}
		return true;
	};

	const pre = (): void => {
		props.setPageIndex(4);
	};

	useEffect(() => {
		props.authTermArr.forEach((e) => {
			e.is_required && requireTerms.push(e.id);
		});
	});
	return (
		<>
			<button onClick={onCheckedAll} className={'flex mb-3 w-full'}>
				{props.authTermArr.length !== checkItems.length ? (
					<FiCircle className={'text-xl text-gray-500 mr-2'} />
				) : (
					<FiCheckCircle className={'text-xl text-green-500 mr-2'} />
				)}
				<span className={'leading-5 w-full text-left'}>전체동의(선택적 동의 포함)</span>
			</button>
			{props.authTermArr.map((e: AuthTermType, k: number) => {
				return (
					<button
						key={k}
						onClick={onCheckedElement}
						id={e.id.toString()}
						className={'leading-6 flex w-full p-3 text-left bg-white mb-3'}
					>
						{e.name}
						<span id={e.id.toString()} onClick={onCheckedElement}>
							({e.is_required ? '필수' : '선택'})
						</span>
						{checkItems.includes(e.id) ? (
							<FiCheckCircle
								id={e.id.toString()}
								onClick={onCheckedElement}
								className={'text-2xl text-green-500 ml-auto'}
							/>
						) : (
							<FiCircle
								id={e.id.toString()}
								onClick={onCheckedElement}
								className={'text-2xl text-gray-500 ml-auto'}
							/>
						)}
					</button>
				);
			})}
			<span
				className={
					(alert !== '' ?? ' hidden') + (alert !== '' ? ' text-red-400' : ' text-green-500')
				}
			>
				{alert}
			</span>
			<button
				className={'underline'}
				onClick={() => window.open('https://bayabas.kr/term', '_blank')}
			>
				약관내용 전체보기
			</button>
			<button
				onClick={pre}
				className={'mt-10 py-4 text-gray-700 border-gray-400'}
				style={{
					border: '1px solid',
				}}
			>
				닉네임 재설정
			</button>
			<button onClick={next} className={'mt-1 py-4 text-white bg-green-500'}>
				회원가입 완료
			</button>
		</>
	);
}
