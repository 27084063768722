import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export default function DiagnosisIntroPage(): ReactElement {
	return (
		<div
			className={`
				h-screen
				grid place-items-center
				sm:px-3
			`}
		>
			<div>
				<img
					src={require('../../assets/images/logo.png')}
					alt={'logo'}
					className={'w-1/3 mb-12 hidden sm:block'}
				/>
				<div className={`text-4xl sm:text-3xl`}>바야바즈!</div>
				<div className={'font-bold text-4xl sm:text-3xl mb-3'}>내 두피 상태를 알려줘!</div>
				<div className={'text-xl sm:text-lg leading-7 py-6'}>
					바야바즈가 제공하는 두피 진단은
					<br />
					유전적 요인, 생활 패턴, 스트레스, 두피 타입 등을
					<br />
					파악하여 현재 두피의 상태와 건강을 진단해요.
				</div>
				<div className={'leading-5 text-slate-700 sm:text-sm mb-8 mt-5'}>
					두피 질병 및 질환은 의료기관을 방문 해 주세요.
				</div>
				<Link to={`/diagnosis/list`}>
					<button
						style={{
							border: 'solid 1px #696969',
							height: '48px',
						}}
						className={`
							w-full mt-2
							bg-white font-medium text-slate-700 text-sm`}
					>
						진단 구경하기
					</button>
				</Link>
			</div>
		</div>
	);
}
