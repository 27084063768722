import { useState, useEffect } from 'react';
import requestUtil from '../../../../utils/request.util';
import { DiagnosisItem } from '../../types/diagnosis/diagnosis-item.type';
import DiagnosisItemContain from '../../containers/diagnosis/diagnosis-item.contain';

export function DiagnosisItemHook(props: { diagnosisId: String }) {
	const [diagnosisItemArr, setDiagnosisItemArr] = useState<Array<DiagnosisItem> | undefined>(
		undefined
	);
	const [loading, setLoading] = useState<Boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);

	const fetchDiagnosis = async () => {
		try {
			setError(undefined);
			setDiagnosisItemArr(undefined);
			setLoading(true);
			const response = await requestUtil(`/diagnosis/item/${props.diagnosisId}`, {
				method: 'GET',
			});
			setDiagnosisItemArr(response.data);
		} catch (e: any) {
			setError(e);
		}
		setTimeout(() => setLoading(false), 300);
	};

	useEffect(() => {
		fetchDiagnosis().then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (loading)
		return (
			<>
				<DiagnosisItemContain diagnosisItemArr={null} />
			</>
		);
	if (error)
		return (
			<div>
				에러... <button onClick={fetchDiagnosis}>다시 불러오기</button>
			</div>
		);
	if (!diagnosisItemArr) return null;
	return <DiagnosisItemContain diagnosisItemArr={diagnosisItemArr} />;
}
