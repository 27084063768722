import React, { ReactElement, useState } from 'react';
import DiagnosisResultCard from '../../constants/diagnosis/diagnosis-result.card';
import DiagnosisGraphCard from '../../constants/diagnosis/diagnosis-graph.card';
import { DiagnosisResultArrayType } from '../../types/diagnosis/diagnosis-result-array.type';
import { DiagnosisResultGraphType } from '../../types/diagnosis/diagnosis-result-graph.type';
import { Link } from 'react-router-dom';
import authUtil from '../../utils/auth.util';
import html2canvas from 'html2canvas';
import requestUtil from '../../../../utils/request.util';

export default function DiagnosisResultCardContain(props: {
	loading?: Boolean;
	dxResultArr?: DiagnosisResultArrayType;
	dxGraph?: DiagnosisResultGraphType;
}): ReactElement {
	const user = authUtil.getCurrentUserInfo();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const sharingImageSave = async () => {
		setIsLoading(true);
		const graphElement = document.getElementById('capture');
		html2canvas(graphElement!)
			.then(async (e) => {
				e.toBlob(
					(imageFile) => {
						let formData = new FormData();
						formData.append('image', imageFile!, 'image.png');
						requestUtil('/diagnosis/result', {
							method: 'POST',
							body: formData,
						}).then(async (res) => {
							// @ts-ignore
							Kakao.Link.sendCustom({
								templateId: 78953,
								templateArgs: {
									THU: res.data,
								},
							});
							setTimeout(() => {
								setIsLoading(false);
							}, 2000);
						});
					},
					'image/png',
					3.0
				);
			})
			.catch((e) => console.log(e));
	};
	return (
		<div className={'sm:my-4 my-24'}>
			<div
				className={`w-full h-full z-50 fixed top-0 left-0 grid items-center opacity-95 ${
					!isLoading ? 'hidden' : ''
				}`}
				style={{
					backgroundColor: '#EDF2F5',
				}}
			>
				<div>
					<img
						alt={'loading-img'}
						src={require('../../../../assets/images/loading.gif')}
						className={'w-32 m-auto'}
					/>
					<p className={'text-center'}> loading...</p>
				</div>
			</div>
			<div
				className="grid grid-cols-5 m-auto sm:grid-cols-none sm:block"
				style={{
					maxWidth: '980px',
				}}
			>
				<div className={'col-span-2 mx-5'}>
					<h1 className={'sm:text-2xl text-4xl leading-normal font-bold'}>
						{user ? user.nickname + ' 님의' : '당신의'} 두피 상태를
						<br />
						알고리즘 기반으로
						<br />
						나타내는 바야바즈 진단서
					</h1>
					<p className={'text-gray-500 mt-8 sm:mt-3 pr-20 sm:pr-5'}>
						진단서를 바탕으로 두피의 타입 및 상태를 파악 후 파악 된 두피의 알맞는 제품 추천 및 생활
						습관을 추천 드려요.
					</p>
					<Link
						to={'recommended'}
						className={
							'w-full place-items-center grid animate-bounce sm:hidden ' +
							'text-center font-light text-sm text-white mt-16'
						}
						style={{
							backgroundColor: '#3e3e3e',
							height: '48px',
						}}
					>
						맞춤추천 보러가기
					</Link>
				</div>
				<div className={'relative col-span-1'} />
				<div className={'col-span-2'} id={'capture'}>
					<DiagnosisGraphCard loading={props.loading} dxGraph={props.dxGraph} />
				</div>
				<Link to={'recommended'}>
					<button
						className={
							'm-auto animate-bounce hidden sm:block text-center text-white w-11/12 text-sm'
						}
						style={{
							backgroundColor: '#3e3e3e',
							height: '48px',
						}}
					>
						맞춤추천 보러가기
					</button>
				</Link>
			</div>
			<DiagnosisResultCard
				loading={props.loading}
				cardType={'indicator'}
				dxResult={props.dxResultArr?.getHairLossRisk()}
			/>
			<DiagnosisResultCard
				loading={props.loading}
				cardType={'image'}
				dxResult={props.dxResultArr?.getHighScore()}
			/>
			<a href={'https://neurocircuit.kr/service/'} target={'_parent'}>
				<img
					className={'hidden sm:block w-screen'}
					src={require('../../../../assets/images/app-introducer.png')}
					alt={'app-introducer'}
				/>
				<img
					className={'sm:hidden w-full mt-24'}
					src={require('../../../../assets/images/app-introducer-web.png')}
					alt={'app-introducer'}
				/>
			</a>
			{/*<DiagnosisDefaultCard loading={props.loading} />*/}
			<div className={'hidden sm:block text-sm text-center'}>
				<div className={'mt-14'}>
					<button
						style={{
							height: '48px',
							backgroundColor: '#3e3e3e',
						}}
						onClick={sharingImageSave}
						className="w-11/12 m-auto block py-3 bg-gray-700 text-white"
					>
						진단서 공유하기
					</button>
					<Link
						to={'/diagnosis'}
						className={'w-11/12 m-auto block text-sm py-3 mt-2'}
						style={{
							height: '48px',
							border: 'solid 1px',
						}}
					>
						진단 다시하기
					</Link>
				</div>
			</div>
			<Link to={'recommended'}>
				<img
					className="m-auto mt-24 sm:hidden"
					style={{
						maxWidth: '980px',
					}}
					src={require('../../../../assets/images/recommended_banner.png')}
					alt={'diagnosis-recommended-banner'}
				/>
			</Link>
		</div>
	);
}
