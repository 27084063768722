import React, { ReactElement, useState } from 'react';

export default function SignUpPasswordContents(props: {
	setUserPassword: Function;
	setPageIndex: Function;
}): ReactElement {
	const [userPassword, setUserPassword] = useState<string>('');
	const [reInputPassword, setReInputPassword] = useState<string>('');
	const [alert, setAlert] = useState<string>('');
	const userPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setUserPassword(e.currentTarget.value);
	};
	const reInputPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setReInputPassword(e.currentTarget.value);
	};
	function checkPassword() {
		const num = userPassword.search(/[0-9]/g);
		const eng = userPassword.search(/[a-z]/gi);

		if (userPassword.length < 8) {
			setAlert('8자리 이상 입력해주세요.');
			return false;
		} else if (userPassword.search(/\s/) !== -1) {
			setAlert('비밀번호는 공백 없이 입력해주세요.');
			return false;
		} else if (num < 0 || eng < 0) {
			setAlert('비밀번호는 영문, 숫자를 혼합하여 입력해주세요.');
			return false;
		} else if (userPassword !== reInputPassword) {
			setAlert('비밀번호가 일치하지 않습니다.');
			return false;
		} else {
			setAlert('');
			props.setUserPassword(userPassword);
			props.setPageIndex(4);
		}
	}
	const pre = (): void => {
		props.setPageIndex(1);
	};
	return (
		<div className={'flex flex-col text-sm'}>
			<input
				className={'py-4 px-5 mb-2'}
				type="password"
				placeholder={'비밀번호를 입력 해 주세요.'}
				value={userPassword}
				onChange={userPasswordHandler}
			/>
			<input
				className={'py-4 px-5 mb-2'}
				type="password"
				placeholder={'비밀번호를 다시 입력 해 주세요.'}
				value={reInputPassword}
				onChange={reInputPasswordHandler}
			/>
			<div className={'flex items-center'}>
				<p className={'text-red-400 ' + (alert === '' && 'hidden')}>
					{alert}
					<br /> 다시 시도해 주세요.
				</p>
			</div>
			<button
				onClick={pre}
				className={'mt-10 py-4 text-gray-700 border-gray-400'}
				style={{
					border: '1px solid',
				}}
			>
				이메일 재입력
			</button>
			<button onClick={checkPassword} className={'mt-1 py-4 text-white bg-green-500'}>
				비밀번호 설정 완료
			</button>
		</div>
	);
}
