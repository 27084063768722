import { DiagnosisResultType } from './diagnosis-result.type';

export class DiagnosisResultArrayType {
	diagnosisResultArr: Array<DiagnosisResultType>;

	constructor(diagnosisResultArr: Array<DiagnosisResultType>) {
		this.diagnosisResultArr = diagnosisResultArr;
	}

	getHairLossRisk = (): DiagnosisResultType => {
		const HairLossRiskResult = this.diagnosisResultArr.filter((diagnosisResult, index) => {
			if (diagnosisResult.name === '탈모') {
				this.diagnosisResultArr.splice(index, 1); // 2nd parameter means remove one item only
			}
			return diagnosisResult.name === '탈모';
		});
		return HairLossRiskResult[0];
	};

	getScalpArea = (): DiagnosisResultType => {
		const HairLossRiskResult = this.diagnosisResultArr.filter((diagnosisResult, index) => {
			if (diagnosisResult.name === '두피면적') {
				this.diagnosisResultArr.splice(index, 1); // 2nd parameter means remove one item only
			}
			return diagnosisResult.name === '두피면적';
		});
		return HairLossRiskResult[0];
	};

	getHighScore = (): DiagnosisResultType => {
		const maxValue = Math.max(...this.diagnosisResultArr.map(({ user_tag_avg }) => +user_tag_avg));
		const highScoreResult = this.diagnosisResultArr.filter((diagnosisResult, index) => {
			return +diagnosisResult.user_tag_avg === maxValue;
		});
		return highScoreResult[0];
	};
}
