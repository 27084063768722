import React, { ReactElement, useEffect, useState } from 'react';
import DiagnosisResultGraphLoader from './loader/diagnosis-result-graph.loader';
import DiagnosisGraphRadar from './diagnosis-graph-radar';
import { DiagnosisResultGraphType } from '../../types/diagnosis/diagnosis-result-graph.type';

export default function DiagnosisGraphCard(props: {
	loading?: Boolean;
	dxGraph?: DiagnosisResultGraphType;
}): ReactElement {
	const [data, setData] = useState<Record<string, unknown>[]>([]);
	useEffect(() => {
		setTimeout(() => {
			setData(
				props.dxGraph
					? props.dxGraph.getData()
					: [
							{ category: '유전', score: 1 },
							{ category: '스트레스', score: 1 },
							{ category: '문제성', score: 1 },
							{ category: '탈모', score: 1 },
					  ]
			);
		}, 100);
	}, [props.dxGraph]);
	return (
		<div className="grid result-graph text-xl font-bold sm:p-0 mb-20 sm:mb-0 sm:mx-0">
			{props.loading ? (
				<div className={'m-auto sm:w-full overflow-hidden'}>
					<div className={'sm:scale-75'}>
						<DiagnosisResultGraphLoader />
					</div>
				</div>
			) : (
				<div className={'sm:scale-95'}>
					<DiagnosisGraphRadar data={data} />
				</div>
			)}
		</div>
	);
}
