import { ReactElement } from 'react';
import { DiagnosisResultType } from '../../types/diagnosis/diagnosis-result.type';
import authUtil from '../../utils/auth.util';

export default function DiagnosisScalpAreaContain(props: {
	loading?: Boolean;
	dxResult?: DiagnosisResultType;
}): ReactElement {
	const user = authUtil.getCurrentUserInfo();

	return props.dxResult ? (
		<div className={'w-full my-32 bg-white'}>
			{/* web view */}
			<div
				className={'grid grid-cols-3 gap-64 m-auto py-6 sm:hidden'}
				style={{
					maxWidth: '980px',
				}}
			>
				<div className={'mx-5 mt-4 rounded-full w-72 h-72 overflow-hidden'}>
					<img
						src={`${props.dxResult!.scalp_image_url}`}
						alt={'scalp area'}
						className={'bg-cover'}
					/>
				</div>
				<div className={'mx-5 col-span-2 sm:col-span-1 p-12'}>
					<p className={'text-gray-500 text-lg font-medium'}>
						바야바즈가 두피의 면적을 측정하여 계산해내요.
					</p>
					<h1 className={'text-3xl font-bold mt-5 mb-5'}>
						<span className={'text-green-500'}>{user ? user.nickname + ' 님의' : '당신의'}</span>{' '}
						두피 면적 결과 입니다.
					</h1>
					<h3 className={'text-xl font-bold mb-5 text-gray-500'}>
						면적{' '}
						<span className={'ml-3 text-black text-2xl text-green-600'}>
							{props.dxResult!.scalp_area_score!.toString().slice(0, 5) + ' %'}
						</span>
					</h3>
					<h3 className={'text-xl font-bold mb-5 text-gray-500'}>
						나이 <span className={'ml-3 text-black'}>{props.dxResult!.age}대</span>
					</h3>
					<h3 className={'text-xl font-bold text-gray-500'}>
						성별{' '}
						<span className={'ml-3 text-black'}>
							{props.dxResult!.gender === 'male' ? '남성' : '여성'}
						</span>
					</h3>
				</div>
			</div>

			{/* app view */}
			<div className={'hidden sm:block px-5 py-6'}>
				<p className={'text-gray-500 font-medium'}>AI가 두피의 면적을 측정하여 계산해내요.</p>
				<h1 className={'text-3xl font-bold mt-5 mb-5 leading-9'}>
					<span className={'text-green-500'}>{user ? user.nickname + ' 님의' : '당신의'}</span>
					<br />
					두피 면적 결과 입니다.
				</h1>
				<div className={'grid grid-cols-2 py-10 items-center gap-10'}>
					<div className={'rounded-full w-36 h-36 overflow-hidden'}>
						<img
							src={`${props.dxResult!.scalp_image_url}`}
							alt={'scalp area'}
							className={'bg-cover'}
						/>
					</div>
					<div className={''}>
						<h3 className={'text-lg font-bold mb-5 text-gray-500'}>
							면적
							<span className={'ml-3 text-black text-2xl text-green-600'}>
								{props.dxResult!.scalp_area_score!.toString().slice(0, 4) + ' %'}
							</span>
						</h3>
						<h3 className={'text-lg font-bold mb-5 text-gray-500'}>
							나이<span className={'ml-3 text-black'}>{props.dxResult!.age}대</span>
						</h3>
						<h3 className={'text-lg font-bold text-gray-500'}>
							성별
							<span className={'ml-3 text-black'}>
								{props.dxResult!.gender === 'male' ? '남성' : '여성'}
							</span>
						</h3>
					</div>
				</div>
			</div>
		</div>
	) : (
		<></>
	);
}
