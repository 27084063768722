import { useState, useEffect, ReactElement } from 'react';
import requestUtil from '../../../../utils/request.util';
import authUtil from '../../utils/auth.util';
import { ProductArrayType } from '../../types/product/productArray.type';
import RecommendedProductContain from '../../containers/product/recommended-product.contain';

export function RecommendedProductHook(): ReactElement {
	const [recommendedProds, setRecommendedProds] = useState<ProductArrayType | undefined>(undefined);
	const [loading, setLoading] = useState<Boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);
	const userToken = authUtil.getCurrentUser();

	const fetchDiagnosis = async () => {
		try {
			setError(undefined);
			setRecommendedProds(undefined);
			setLoading(true);
			const dxLogId_1 = localStorage.getItem('diagnosis_1');
			const response = await requestUtil(
				`${userToken ? '/product/recommended' : '/product/none/user/recommended?partner=pinkda'}`,
				{
					method: userToken ? 'GET' : 'POST',
					headers: {
						'Content-Type': 'application/json',
						'bayabas': userToken ? userToken.jwtToken : '',
					},
					body:
						dxLogId_1 &&
						JSON.stringify({
							dx_log_id: dxLogId_1,
						}),
				}
			);

			setRecommendedProds(new ProductArrayType(response.data));
		} catch (e: any) {
			setError(e);
		}
		// setLoading(false);
		setTimeout(() => setLoading(false), 300);
	};

	useEffect(() => {
		fetchDiagnosis().then();
		/* eslint-disable */
	}, []);
	if (loading) return <></>;
	if (error)
		return (
			<>
				<div>{error.toString()}</div>
			</>
		);
	if (!recommendedProds) return <>null</>;
	return (
		<>
			<RecommendedProductContain Products={recommendedProds} />
		</>
	);
}
