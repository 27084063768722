import React, { Component } from 'react';

class NotFoundPage extends Component {
	render() {
		return (
			<div className={'text-center'}>
				<h1>404 Not Found</h1>
			</div>
		);
	}
}

export default NotFoundPage;
