import React from 'react';
import ContentLoader from 'react-content-loader';

const DiagnosisResultCardLoader = () => (
	<ContentLoader
		speed={2}
		width={750}
		height={135}
		viewBox="0 0 750 135"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="0" y="0" rx="2" ry="2" width="100" height="25" />
		<rect x="0" y="33" rx="2" ry="2" width="560" height="20" />
		<rect x="0" y="58" rx="2" ry="2" width="560" height="20" />
		<rect x="0" y="83" rx="2" ry="2" width="560" height="20" />
		<rect x="0" y="108" rx="2" ry="2" width="560" height="20" />
		<circle cx="680" cy="68" r="65" />
	</ContentLoader>
);

export default DiagnosisResultCardLoader;
