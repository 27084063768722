import React, { ReactElement } from 'react';
import { ProductType } from '../../types/product/product.type';

export default function RecommendedProductCard(props: { product: ProductType }): ReactElement {
	return (
		<>
			<a href={props.product.purchase_link} target={'_parent'}>
				<div className={'prod-wrap mt-10 bg-white px-8 pb-10 sm:p-3 sm:m-0 sm:px-5'}>
					<div className={'relative'}>
						<img
							className={'my-3 rounded-md py-8'}
							src={JSON.parse(props.product.thumbnail_image_array)[0].toString()}
							alt={'recommended_title'}
						/>
					</div>
					<div className={'mt-6'}>
						<div className={'flex text-xs text-white font-bold text-xs mb-3'}>
							{JSON.parse(props.product.tags).map((e: any, k: number) => {
								return (
									<p className={'mr-2 p-1 px-2 rounded-3xl bg-gray-700 font-light'} key={k}>
										{e}
									</p>
								);
							})}
						</div>
						<div
							style={{
								minHeight: '200px',
							}}
						>
							{/*title*/}
							<p className={'text-gray-500 text-sm'}>{props.product.title}</p>
							{/*name*/}
							<p className={'text-lg font-bold'}>{props.product.name}</p>
							{/*subtitle*/}
							<p className={'mt-1 mb-3 text-sm'}>{props.product.subtitle}</p>
							{/*price*/}
							<p className={'text-xl font-bold mt-1 ml-1'}>
								&#8361; {props.product.guava_discount.toLocaleString('ko-KR')}
							</p>
						</div>
						<button
							className={'m-auto text-center w-full text-white mt-5'}
							style={{
								backgroundColor: '#3e3e3e',
								height: '48px',
							}}
						>
							제품 구매하기
						</button>
					</div>
				</div>
			</a>
		</>
	);
}
