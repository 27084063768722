import React, { ReactElement } from 'react';
import { DiagnosisItem } from '../../types/diagnosis/diagnosis-item.type';

export default function DiagnosisItemCard(props: { diagnosisItem: DiagnosisItem }): ReactElement {
	return (
		<>
			<div className="title text-xl sm:text-lg font-bold">{props.diagnosisItem.question}</div>
			<div className="description font-light sm:text-sm mt-2">
				{props.diagnosisItem.question_explanation}
			</div>
		</>
	);
}
