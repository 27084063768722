import React from 'react';
import ContentLoader from 'react-content-loader';

const DiagnosisCardLoader = () => (
	<ContentLoader
		speed={2}
		width={750}
		height={200}
		viewBox="0 0 476 124"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="1" y="16" rx="0" ry="0" width="91" height="5" />
		<rect x="1" y="31" rx="3" ry="3" width="129" height="14" />
		<rect x="0" y="57" rx="3" ry="3" width="309" height="6" />
		<rect x="0" y="74" rx="3" ry="3" width="309" height="6" />
		<circle cx="414" cy="57" r="41" />
		<circle cx="8" cy="98" r="6" />
		<circle cx="83" cy="98" r="6" />
		<rect x="19" y="95" rx="3" ry="3" width="41" height="6" />
		<rect x="96" y="95" rx="3" ry="3" width="41" height="6" />
	</ContentLoader>
);

export default DiagnosisCardLoader;
