import React, { ReactElement } from 'react';
import authUtil from '../../utils/auth.util';
import { RecommendedProductHook } from '../../hooks/product/recommended-product.hook';
import { RecommendedSolutionHook } from '../../hooks/solution/recommended-solution.hook';
import { Link } from 'react-router-dom';

export default function DiagnosisRecommendedPage(): ReactElement {
	try {
		const user = authUtil.getCurrentUserInfo();
		return (
			<>
				<div className={'mt-32 sm:my-10'}>
					<div
						className={'mt-12 sm:mx-0 m-auto'}
						style={{
							maxWidth: '1100px',
						}}
					>
						<p className={'mb-3 text-gray-500 text-xl sm:text-sm sm:mx-5'}>
							내 두피에는 어떤 제품이 잘 맞을까요?
						</p>
						<h1 className={'text-3xl font-bold leading-10 sm:mx-5 sm:mb-5'}>
							<span className={'text-green-500'}>{user && user.nickname}</span>
							{user ? ' 님의' : '당신의'}
							<br />
							두피 상태를 기반으로
							<br />
							추천된 제품들이에요.
						</h1>
						<div>
							<RecommendedProductHook />
						</div>
					</div>
					<img
						src={require('../../assets/images/hell-app.png')}
						alt={'hello-app'}
						className={'mt-16 hidden sm:block'}
					/>
					<div
						className={'mt-20 sm:mx-0 m-auto'}
						style={{
							maxWidth: '1100px',
						}}
					>
						<p className={'mb-3 text-gray-500 text-xl sm:text-sm sm:mx-5'}>
							내 두피에는 어떤 관리가 필요할까요?!
						</p>
						<h1 className={'text-3xl font-bold leading-10 sm:mx-5'}>
							<span className={'text-green-500'}>{user && user.nickname}</span>
							{user ? ' 님의' : '당신의'}
							<br />
							두피 상태를 기반으로
							<br />
							나온 추천 관리법들이에요.
						</h1>
						<button
							style={{
								backgroundColor: '#3e3e3e',
							}}
							onClick={() => window.open('https://neurocircuit.kr/service/', '_parent')}
							className="
                           block text-center mt-5 text-sm px-8 text-white sm:hidden py-2
                          "
						>
							앱에서 관리 시작하기
						</button>
						{/*	solution recommended hook*/}
						<RecommendedSolutionHook />
						{/* bottom button wrap*/}
						<div className={'mt-14 hidden sm:block sm:mx-5'}>
							<button
								style={{
									backgroundColor: '#3e3e3e',
									height: '48px',
								}}
								onClick={() => window.open('https://neurocircuit.kr/service/', '_parent')}
								className="
                           block w-full text-center text-sm py-3 text-white
                          "
							>
								앱에서 관리 시작하기
							</button>
							<Link
								to={'/diagnosis'}
								className={'block w-full text-center text-sm py-3 mt-2'}
								style={{
									height: '48px',
									border: 'solid 1px',
								}}
							>
								진단 다시하기
							</Link>
						</div>
					</div>
					<img
						src={require('../../assets/images/hell-app-web.png')}
						alt={'hello-app'}
						className={'mt-32 sm:hidden w-screen'}
					/>
				</div>
			</>
		);
	} catch (e: any) {
		return <>정상적인 접근이 아닙니다. 다시 시도해 주세요. 🥺</>;
	}
}
