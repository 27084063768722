export class UserType {
	nickname?: string;
	profile_image_url?: string;
	email?: string;
	guava_count?: number;
	constructor(
		nickname?: string,
		profile_image_url?: string,
		email?: string,
		gender?: string,
		guava_count?: number
	) {
		this.nickname = nickname;
		this.profile_image_url = profile_image_url;
		this.email = email;
		this.guava_count = guava_count;
	}

	setUser = (json: any) => {
		this.nickname = json['nickname'];
		this.profile_image_url = json['profile_image_url'];
		this.email = json['email'];
		this.guava_count = json['guava_count'];
	};
}
